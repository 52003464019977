import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setAddresses, setAddress } from "../AddressForm/addressReducer";

import * as client from "../../Services/client";

import "./SavedAddressCard.css";
import React  from 'react';

export default function SavedAddressCard({ handleNext }) {
    const dispatch = useDispatch();

    const [isSelected, setIsSelected] = useState("");

    const addresses = useSelector((state) => state.addressReducer.addresses);

    useEffect(() => {
        client.getAddresses().then((addresses) => {
            dispatch(setAddresses(addresses.addresses));
        });
    }, [dispatch]);

    const handleSelectSavedAddress = (addressId) => {
        setIsSelected(addressId);
    };

    const handleSubmit = (address) => {
        dispatch(setAddress(address));
        handleNext();
    };

    return (
        <div className="fluid-container">
            <div className="row">
                {addresses &&
                    addresses.map((address) => (
                        <div
                            key={address.id}
                            onClick={() => handleSelectSavedAddress(address.id)}
                            className={`pt-2 col-lg-5 m-3 rounded border-1 highlight bg-white ${
                                isSelected === address.id ? "selected" : ""
                            }`}
                        >
                            <div className="">
                                <p className="fw-bold">
                                    Name: {address.firstname} {address.lastname}
                                </p>
                                <p className="">Street: {address.addressLine1}</p>
                                <p className="">Apartment/Suite: {address.addressLine2}</p>
                                <p className="">
                                    City: {address.city}, {address.state} {address.zipcode}
                                </p>
                                <p className="">Country: {address.country}</p>
                                <p className="fw-bold">Mobile: {address.phone}</p>
                                {isSelected === address.id && (
                                    <button 
                                        className="mb-3 btn w-100 btn-dark rounded-pill"
                                        onClick={() => handleSubmit(address)}>
                                            Select
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}