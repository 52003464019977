import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./FailedPage.css";
import {FiAlertTriangle} from 'react-icons/fi';
import {useContext} from "react";

const FailedPage = () => {

  return (
    <div>
      <Header/>

      <div class="containerfp md:mx-32 md:my-28 mb-14 mt-6 ">
        <div class="ui middle aligned center aligned grid">
          <div class="ui eight wide column border-3 flex justify-center border-red-600 bg-red-100 p-10 ">
            <form class="ui large form">
              <div class="ui icon negative message ">
                {/* <div className=" bg-red flex flex-col justify-center "><FiAlertTriangle/></div> */}
                <div class="content">
                  <div class="header flex justify-center md:text-[27px] text-[22px] text-center ">Oops! Something went wrong.</div>
                  <p className=" md:text-[21px] text-[17px] text-center ">While trying to reserve money from your account</p>
                </div>
              </div>

              <span class="ui large teal submit fluid button flex justify-center md:text-[21px] text-[19px] md:mt-0 mt-3 ">Try again</span>
            </form>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default FailedPage;
