import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cart-slice";
import cartReducer from "../Components/Cart/cartReducer";
import addressReducer from "../Components/AddressForm/addressReducer";
import loginReducer from "../Components/LoginModal/loginReducer";

const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    cartReducer,
    addressReducer,
    loginReducer,
  },
});

export default store;