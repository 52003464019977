import axios from "axios";

const BASE_URL = "https://ecom.urbankarmagb.co.uk";

const auth_token = localStorage.getItem("token");

export const getCartItems = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/users/cart`, {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching cart items:", error);
    throw error;
  }
};

export const removeCartItem = async (cartProductVariantId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/users/cart/${cartProductVariantId}`, {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error removing cart product variant:", error);
    throw error;
  }
}

export const updateCartItemQuantity = async (cartItemId, quantity) => {
  try {
    const response = await axios.put(`${BASE_URL}/users/cart/${cartItemId}`, {
      quantity,
    }, {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating cart item quantity:", error);
    throw error;
  }
}

export const addAddress = async(address) => {
    try {
        const response = await axios.post(`${BASE_URL}/users/address/add`, address, {
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error adding address:", error);
        throw error;
    }
}

export const getAddresses = async() => {
    try {
        const response = await axios.get(`${BASE_URL}/users/addresses`, {
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching addresses:", error);
        throw error;
    }
}

// export const userLogin = async (email, password) => {
//   try {
//     const response = await axios.post(`${BASE_URL}/users/login`, {
//       email,
//       password,
//     });

//     window.location.reload(false);
//     return response.data;
//   } catch (error) {
//     console.error("Error logging in:", error);
//     throw error;
//   }
// }

export const addToCart = async (variantId, quantity) => {
  const authToken = localStorage.getItem("token"); // Retrieve the token here

  try {
    const response = await axios.post(`${BASE_URL}/users/cart/add`, {
      variantId,
      quantity,
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Use the retrieved token
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error adding to cart:", error);
    throw error;
  }
}

export const userLogin = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_URL}/users/login`, {
      email,
      password,
    });

    // Save the token in localStorage
    localStorage.setItem("token", response.data.token);

    // Retrieve cart items from localStorage
    const cartItems = JSON.parse(localStorage.getItem('cartItems'));

    if (cartItems && cartItems.length > 0) {
        // Add each item to the user's cart
        for (const item of cartItems) {
            await addToCart(item.variantId, item.quantity);
        }

        // Clear the cartItems from localStorage after adding to user's cart
        localStorage.removeItem('cartItems');
    }

    // Optionally, reload the page to update UI
    window.location.reload();

    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};
