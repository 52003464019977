import React, { useState, useRef } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { GoDot } from "react-icons/go";
import Slider from "react-slick";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import "./Orders.css";

const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 3;

  // Sample order data (replace with your actual order data)
  const orderHistory = [
    {
      id: 13432,
      status: "In Progress",
      imageUrl: "./dress1.jpg",
    },
    {
      id: 2,
      status: "Processing",
      imageUrl: "./dress2.jpg",
    },
    {
      id: 3,
      status: "Delivered",
      imageUrl: "./dress3.jpg",
    },
    {
      id: 4,
      status: "Shipped",
      imageUrl: "./dress1.jpg",
    },
    {
      id: 5,
      status: "Delivered",
      imageUrl: "./dress2.jpg",
    },
    {
      id: 6,
      status: "Processing",
      imageUrl: "./dress1.jpg",
    },
    // Add more order items
  ];

  const totalPages = Math.ceil(orderHistory.length / ordersPerPage);

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const visibleOrders = orderHistory.slice(
    (currentPage - 1) * ordersPerPage,
    currentPage * ordersPerPage
  );

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const sliderRef = useRef();
  var settings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: windowSize.current[0] > 600 ? 2 : 1,
    slidesToScroll: 3,
  };

  return (
    <div>
      <Header />

      <div className=" md:ml-6 md:mt-14 md:my-0 my-6 md:p-0 p-4 ">
        <h1 className=" font-bold md:text-[35px] text-[26px] ">Order history</h1>
        <h5 className=" !font-normal md:text-[18px] text-[14px] ">
          Check the status of recent orders, manage returns, and discover
          similar products.
        </h5>
      </div>
      
      <div class=" hidden md:block relative overflow-x-auto shadow-md sm:rounded-lg p-10 ">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 !p-10 ">
          <thead className=" text-[18px] text-gray-700 uppercase bg-white ">
            <tr>
              <th scope="col" className="px-6 py-4 !font-semibold">
                Items
              </th>
              <th scope="col" className="px-6 py-4 !font-semibold">
                PRICE
              </th>
              <th scope="col" className="px-6 py-4 !font-semibold">
                QUANTITY
              </th>
              <th scope="col" className="px-6 py-4 !font-semibold">
                TOTAL
              </th>
              {/* <th scope="col" class="px-6 py-3">
                    <span class="sr-only">Edit</span>
                </th> */}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th
                scope="row"
                class="px-6 py-4 text-gray-900 whitespace-nowrap "
              >
                <div className=" flex ">
                  <img className="h-48" src="dress2.jpg" />
                  <div className=" flex flex-col ml-4 ">
                    <div className=" !font-semibold my-1 text-[15px] cursor-pointer ">
                      FRENCH CONNECTION
                    </div>
                    <div className=" text-[14px] my-1 font-normal ">
                      French Connection, straw bag
                    </div>
                    <div className=" text-[14px] my-1 font-normal ">Color: Black</div>
                    <div className=" text-[14px] my-1 font-semibold ">
                      Gift Wrapping: Not Added
                    </div>
                  </div>
                </div>
              </th>
              <td className="px-6 py-4">
                <div className=" my-4 ">
                  <div className=" font-semibold text-[16px] text-black ">$70.00</div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className=" my-3 ">
                  <div className=" border-1 border-gray-200 px-4 py-2 flex items-center text-gray-600 w-14 ">
                    1
                  </div>
                </div>
              </td>
              <td class="px-6 py-4">
                <div className=" my-4 ">
                  <div className=" font-semibold text-[16px] text-black">$80.00</div>
                </div>
              </td>
              {/* <td class="px-6 py-4 text-right">
                    <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td> */}
            </tr>

            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 hover:!bg-sky-50 cursor-pointer ">
              <th
                scope="row"
                class="px-6 py-4 text-gray-900 whitespace-nowrap "
              >
                <div className=" flex ">
                  <img className="h-48" src="dress1.jpg" />
                  <div className=" flex flex-col ml-4 ">
                    <div className=" font-semibold my-1 text-[15px] cursor-pointer ">
                      FRENCH CONNECTION
                    </div>
                    <div className=" text-[14px] my-1 font-normal ">
                      French Connection, straw bag
                    </div>
                    <div className=" text-[14px] my-1 font-normal ">Color: Black</div>
                    <div className=" text-[14px] my-1 font-semibold ">
                      Gift Wrapping: Not Added
                    </div>
                  </div>
                </div>
              </th>
              <td className="px-6 py-4">
                <div className=" my-4 ">
                  <div className=" font-semibold text-[16px] text-black ">$70.00</div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className=" my-3 ">
                  <div className=" border-1 border-gray-200 px-4 py-2 flex items-center text-gray-600 w-14 ">
                    1
                  </div>
                </div>
              </td>
              <td class="px-6 py-4">
                <div className=" my-4 ">
                  <div className=" font-semibold text-[16px] text-black">$80.00</div>
                </div>
              </td>
              {/* <td class="px-6 py-4 text-right">
                    <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td> */}
            </tr>

            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600  ">
              <th
                scope="row"
                class="px-6 py-4 text-gray-900 whitespace-nowrap "
              >
                <div className=" flex ">
                  <img className="h-48" src="dress3.jpg" />
                  <div className=" flex flex-col ml-4 ">
                    <div className=" font-semibold my-1 text-[15px] cursor-pointer ">
                      FRENCH CONNECTION
                    </div>
                    <div className=" text-[14px] my-1 font-normal ">
                      French Connection, straw bag
                    </div>
                    <div className=" text-[14px] my-1 font-normal ">Color: Black</div>
                    <div className=" text-[14px] my-1 font-semibold ">
                      Gift Wrapping: Not Added
                    </div>
                  </div>
                </div>
              </th>
              <td className="px-6 py-4">
                <div className=" my-4 ">
                  <div className=" font-semibold text-[16px] text-black ">$70.00</div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className=" my-3 ">
                  <div className=" border-1 border-gray-200 px-4 py-2 flex items-center text-gray-600 w-14 ">
                    1
                  </div>
                </div>
              </td>
              <td class="px-6 py-4">
                <div className=" my-4 ">
                  <div className=" font-semibold text-[16px] text-black">$80.00</div>
                </div>
              </td>
              {/* <td class="px-6 py-4 text-right">
                    <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td> */}
            </tr>
          </tbody>
        </table>
      </div>



      <div className=" md:hidden block p-4 mb-10 bg-white ">

        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        <div className="">
          <div className=" flex justify-center ">
            <div className="">
              <img className=" h-32 " src="dress1.jpg" />
            </div>
            <div className=" text-[14px] ml-4 ">
               <p className=" font-bold mb-2 !text-[17px] ">FRENCH CONNECTION</p>
               <p className=" mb-1.5 ">French Connection Bag</p>
               <p className=" mb-1.5 ">Color: Black</p>
               <p className=" mb-1.5 ">Gift Wrapping: Not Added</p>
            </div>
          </div>

          <div className="flex justify-between mt-10 ">
            <div className="">
               <div className=" mb-4 ">Price</div>
               <div className=" mb-4 ">Quantity</div>
               <div className="">Total</div>
            </div>
             <div className="">
               <div className=" font-semibold mb-3 ">$80.00</div>
               <div className=" mb-3  "><div className=" border-1 border-gray-200 px-4 py-2 flex items-center text-gray-600 w-14 ">
                    2
                  </div></div>
               <div className=" font-semibold ">$160.00</div>
            </div>            
         </div>

         <div className=" text-center mt-6 text-[19px] ">
              <p className=" font-semibold">Delivered on 3rd November</p>
          </div>

        </div>

        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        <div className=" mt-10 ">
          <div className=" flex justify-center ">
            <div className="">
              <img className=" h-32 " src="dress1.jpg" />
            </div>
            <div className=" text-[14px] ml-4 ">
               <p className=" font-bold mb-2 !text-[17px] ">FRENCH CONNECTION</p>
               <p className=" mb-1.5 ">French Connection Bag</p>
               <p className=" mb-1.5 ">Color: Black</p>
               <p className=" mb-1.5 ">Gift Wrapping: Not Added</p>
            </div>
          </div>

          <div className="flex justify-between mt-10 ">
            <div className="">
               <div className=" mb-4 ">Price</div>
               <div className=" mb-4 ">Quantity</div>
               <div className="">Total</div>
            </div>
             <div className="">
               <div className=" font-semibold mb-3 ">$80.00</div>
               <div className=" mb-3  "><div className=" border-1 border-gray-200 px-4 py-2 flex items-center text-gray-600 w-14 ">
                    2
                  </div></div>
               <div className=" font-semibold ">$160.00</div>
            </div>
           
          </div>

          <div className=" text-center mt-6 text-[19px] ">
              <p className=" font-semibold">On the Way</p>
          </div>

        </div>

        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />



      </div>

      <Footer />
    </div>
  );
};

export default Orders;
