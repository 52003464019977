import ReactDom from "react-dom";
import "./LoginModal.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "./loginReducer";
import * as client from "../../Services/client";

const LoginModal = ({ open, children, onClose, handleNext }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const loginMe = () => {
    setIsLoading(true);
    setLoginError("");
    client
      .userLogin(email, password)
      .then((res) => {
          dispatch(login(res));
          localStorage.setItem("token", res.token);
          onClose();
      })
      .catch((err) => {
        setLoginError("Invalid email/password combination");
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLE}></div>
      <div style={LOGIN_MODAL_STYLES}>
        <div style={LOGIN_FORM_MODAL}>
          <i
            style={FONT_CROSS_STYLE}
            onClick={onClose}
            className="fa-solid fa-xmark"
          ></i>
          {loginError && <div className="alert alert-danger">{loginError}</div>}
          <div className="email-password">
            <label style={LABEL_STYLE} htmlFor="email">
              Email Address
            </label>
            <input
              className="input-email-password"
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="email-password">
            <label style={LABEL_STYLE} htmlFor="pass">
              Password
            </label>
            <input
              className="input-email-password"
              type="password"
              id="pass"
              name="password"
              minLength="8"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="login-register-btn">
            <button
              type="submit"
              className="login-hover"
              style={LOGIN_BUTTTON_MODAL}
              onClick={loginMe}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {' Loading...'}
                </>
              ) : (
                'LOGIN'
              )}
            </button>
            <a
              href="/register"
              className="register-hover"
              style={REGISTER_BUTTON_MODAL}
            >
              REGISTER
            </a>
          </div>
          <a className="forgot-password" href="/forgotpassword">
            Forgot your password?
          </a>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

const LOGIN_MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  backgroundColor: "white",
  padding: "50px",
  zIndex: "1000",
  width: "38%",
  minWidth: "500px",
};

const OVERLAY_STYLE = {
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  zIndex: "2",
  backgroundColor: "rgba(0,0,0,0.7)",
};

const LOGIN_FORM_MODAL = {
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const FONT_CROSS_STYLE = {
  position: "absolute",
  top: "20px",
  right: "30px",
  color: "#2C062C",
  fontSize: "2rem",
  cursor: "pointer",
};

const LOGIN_BUTTTON_MODAL = {
  transition: "all 0.3s ease-in-out",
  boxSizing: "border-box",
  letterSpacing: "1px",
  padding: "8px 25px",
  fontSize: "0.8rem",
  fontWeight: "bold",
};

const REGISTER_BUTTON_MODAL = {
  transition: "all 0.3s ease-in-out",
  textDecoration: "none",
  textAlign: "center",
  letterSpacing: "1px",
  padding: "8px 25px",
  fontSize: "0.8rem",
  fontWeight: "bold",
};

const LABEL_STYLE = {
  fontWeight: "bolder",
  fontSize: "0.8rem",
  letterSpacing: "1px",
};

export default LoginModal;
