import { Link } from "react-router-dom";
import React  from 'react';

import { useDispatch } from "react-redux";
import { resetAddress } from "../AddressForm/addressReducer";

import { useSelector } from "react-redux";
import { Radio, FormControlLabel } from "@mui/material";

export default function AddressAndPaymentCard({ handlePrevious }) {
    const dispatch = useDispatch();

    const handleChangeAddress = () => {
        dispatch(resetAddress());
        handlePrevious();
    }

    // handling current address
    const selectedAddress = useSelector((state) => state.addressReducer.address);

    return (
        <div className="bg-light border-1 fluid-container p-2">
            <div className="row text-sm">
                <div className="col-md-7">
                    <span className="fw-bold" style={{ color: 'rgb(76,24,76)' }}>Shipping Address </span>
                    [<Link to="#" className="text-sm" onClick={handleChangeAddress}>Change</Link>]
                    <br />
                    {selectedAddress.firstname} {selectedAddress.lastname}
                    <br />
                    {selectedAddress.addressLine1}
                    <br />
                    {selectedAddress.addressLine2}
                    <br />
                    {selectedAddress.landmark} <br />
                    {selectedAddress.city}
                    <br />
                    {selectedAddress.state} - {selectedAddress.postcode}
                    <br />
                    {selectedAddress.country}
                    <br />
                    <span className="fw-bold">Phone: </span> {selectedAddress.phone}
                </div>
                <div className="col-md-4 p-0">
                    <span className="fw-bold" style={{ color: 'rgb(76,24,76)' }}>Payment Method </span>
                    <form className="form-group text-xs">
                        <div className="radio-group">
                            <label>
                                <input
                                    type="radio"
                                    value="credit-debit-netbanking"
                                    name="paymentMethod"
                                    className="me-1"
                                />
                                Credit/Debit Card/ Netbanking
                            </label>
                        </div>
                        <div className="radio-group d-flex">
                            <label>
                                <input
                                    type="radio"
                                    value="uk-wallet"
                                    name="paymentMethod"
                                    className="me-1"
                                />
                                Urban Karma Wallet (0 credits)
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}