import React,{useState} from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./TrackingPage.css";

const TrackingPage = () => {

    const [trackingNumber, setTrackingNumber] = useState('');
    const [trackingStatus, setTrackingStatus] = useState(null);
  
    const handleTrackingNumberChange = (e) => {
      setTrackingNumber(e.target.value);
    };
  
    const trackOrder = () => {
      // You would typically make an API request to get the tracking status
      // Here, we are simulating it with a timeout
      setTrackingStatus('Loading...');
  
      setTimeout(() => {
        setTrackingStatus('Your order is on the way!');
      }, 2000);
    };


    return(
        <div>
            <Header/>

            <div className="">

            {/* <div class="container4">
    <div class="card">
        <header class="card-header"> My Orders / Tracking </header>
        <div class="card-body">
            <h6>Order ID: OD45345345435</h6>
            <article class="card">
                <div class="card-body row">
                    <div class="col"> <strong>Estimated Delivery time:</strong> <br/>29 nov 2019 </div>
                    <div class="col"> <strong>Shipping BY:</strong> <br/> BLUEDART, | <i class="fa fa-phone"></i> +1598675986 </div>
                    <div class="col"> <strong>Status:</strong> <br/> Picked by the courier </div>
                    <div class="col"> <strong>Tracking #:</strong> <br/> BD045903594059 </div>
                </div>
            </article>
            <div class="track">
                <div class="step active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span class="text">Order confirmed</span> </div>
                <div class="step active"> <span class="icon"> <i class="fa fa-user"></i> </span> <span class="text"> Picked by courier</span> </div>
                <div class="step"> <span class="icon"> <i class="fa fa-truck"></i> </span> <span class="text"> On the way </span> </div>
                <div class="step"> <span class="icon"> <i class="fa fa-box"></i> </span> <span class="text">Ready for pickup</span> </div>
            </div>
            <hr>
            <ul class="row">
                <li class="col-md-4">
                    <figure class="itemside mb-3">
                        <div class="aside"><img src="https://i.imgur.com/iDwDQ4o.png" class="img-sm border"/></div>
                        <figcaption class="info align-self-center">
                            <p class="title">Dell Laptop with 500GB HDD <br/> 8GB RAM</p> <span class="text-muted">$950 </span>
                        </figcaption>
                    </figure>
                </li>
                <li class="col-md-4">
                    <figure class="itemside mb-3">
                        <div class="aside"><img src="https://i.imgur.com/tVBy5Q0.png" class="img-sm border"/></div>
                        <figcaption class="info align-self-center">
                            <p class="title">HP Laptop with 500GB HDD <br/> 8GB RAM</p> <span class="text-muted">$850 </span>
                        </figcaption>
                    </figure>
                </li>
                <li class="col-md-4">
                    <figure class="itemside mb-3">
                        <div class="aside"><img src="https://i.imgur.com/Bd56jKH.png" class="img-sm border"/></div>
                        <figcaption class="info align-self-center">
                            <p class="title">ACER Laptop with 500GB HDD <br/> 8GB RAM</p> <span class="text-muted">$650 </span>
                        </figcaption>
                    </figure>
                </li>
            </ul>
            </hr>
            <a href="#" class="btn btn-warning" data-abc="true"> <i class="fa fa-chevron-left"></i> Back to orders</a>
           </div>
         </div>
       </div> */}




       <div className="container flex justify-center bg-white border-2 rounded-xl border-gray-200 !my-20 p-4 ">
      <h1 className="text-2xl font-bold mb-4 mx-4 ">Track Your Order</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Enter tracking number"
          value={trackingNumber}
          onChange={handleTrackingNumberChange}
          className="w-full p-2 border rounded shadow"
        />
      </div>
      <div className="mb-4">
        <button
          onClick={trackOrder}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Track
        </button>
      </div>
      {trackingStatus && (
        <div className="text-lg font-semibold">{trackingStatus}</div>
      )}
    </div>



          </div>
        
          <Footer/>
        </div>
    )
}

export default TrackingPage;