import "./style.css"
import React  from 'react';

import { useDispatch } from "react-redux";
import { resetAddress } from "../AddressForm/addressReducer";

export default function OrderSummary({ netValue, btnAction }) {
    const dispatch = useDispatch();

    const handlePlaceOrder = () => {
        dispatch(resetAddress());
        btnAction();
    }

    return (
        <div className='bg-light border-1 p-2 rounded margin-container mt-2'>
            <div className='row'>
                <div className='col-12 mt-1 d-flex flex-column justify-content-between'>
                    <div>
                        <div className="row ms-0 fw-bold">
                            Order Summary:
                        </div>
                        <div className='row small-text-font'>
                            <div className='d-flex justify-content-between'>
                                    Item: <span className="float-end">£{netValue.toFixed(2)}</span>
                            </div>
                        </div>
                        <div className='row small-text-font'>
                            <span>
                            Platform handling fee: <span className="float-end text-success">Free</span>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div className='mb-2 d-flex justify-content-between'>
                        <div>
                            Total: 
                        </div>
                        <div>
                        £{netValue.toFixed(2)}
                        </div>
                    </div>

                    <div className='w-100'>
                        <button className='w-100 btn btn-dark btn-sm rounded-pill' onClick={handlePlaceOrder}>
                            PLACE ORDER
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};