import React from "react";

const ReturnRefund = () => {
   return(
    <div>

        <div className=" p-16 ">
            <div className=" text-[35px] mt-2 mb-6 font-semibold ">Return Policy</div>
            <div className=" text-gray-600 ">You may return most new, unopened items within 30 days of delivery for a full refund. We'll also pay the return shipping costs if the return is a result of our error (you received an incorrect or defective item, etc.).</div>
            <br/>
            <div className=" text-gray-600 ">You should expect to receive your refund within four weeks of giving your package to the return shipper, however, in many cases you will receive a refund more quickly. This time period includes the transit time for us to receive your return from the shipper (5 to 10 business days), the time it takes us to process your return once we receive it (3 to 5 business days), and the time it takes your bank to process our refund request (5 to 10 business days).</div>
            <br/>
            <div className=" text-gray-600 ">If you need to return an item, simply login to your account, view the order using the "Complete Orders" link under the My Account menu and click the Return Item(s) button. We'll notify you via e-mail of your refund once we've received and processed the returned item.</div>
        </div>
    </div>
   )
}

export default ReturnRefund;