import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // success: null,
    // message: "",
    // token: "",
    // id: "",
    // email: "",
    // name: "",
    // gender: null,
    // dateOfBirth: "",
    // profileImage: "",
    // phone: null,
    authResponse: {},
}

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        login: (state, action) => {
            state.authResponse = action.payload;
        },
        logout: (state) => {
            state.authResponse = {};
        },
    },
});

export const { login, logout } = loginSlice.actions;
export default loginSlice.reducer;