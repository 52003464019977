import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./UserProfile.css";

const UserProfile = () => {
  const edit = () => {
    alert("Edit Option Available");
  };

  const save = () => {
    alert("Changes Saved");
  };

  const [userInfo, setUserInfo] = useState({
    userName: "John Doe",
    phoneNumber: "123-456-7890",
    gender: "Male",
    email: "johndoe@example.com",
    address: "123 Main St, City, Country",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddress({
      ...address,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(address);
    // You can perform further actions, such as sending the address data to a server.
  };

  return (
    <div>
      <Header />

      <div className=" flex justify-center mt-16 ">
        <p className=" text-[35px] font-bold ">MANAGE MY ACCOUNT</p>
      </div>

      <div className=" flex justify-evenly ">
        <div className=" p-10 w-1/2 ">
          <div class=" mb-5 ">
            <div class=" px-4 my-4 rounded">
              <div class="flex flex-col ">
                <h4 class=" mb-4 font-semibold text-[34px] ">User Info</h4>

                <form className=" md:mb-10 " onSubmit={handleSubmit}>
                  <div class=" text-[17px] mt-2 text-gray-700 ">
                    <label class=" font-semibold ">First Name *</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="First name"
                      // value="Scaralet"
                    />
                    {/* <input type="text" id="first_name" name="first_name" required /> */}
                  </div>

                  <div class=" text-[17px] mt-4 text-gray-700 ">
                    <label class="font-semibold">Last Name *</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="Last name"
                      // value="Doe"
                    />
                    {/* <input type="text" id="last_name" name="last_name" required /> */}
                  </div>

                  <div class=" text-[17px] mt-4 text-gray-700 ">
                    <label class="font-semibold  ">Phone number *</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="Phone number"
                      // value="(333) 000 555"
                    />
                    {/* <input type="tel" id="phone_number" name="phone_number" pattern="[0-9]{10}" required /> */}
                  </div>

                  <div class=" text-[17px] mt-4 text-gray-700 ">
                    <label class="font-semibold ">Mobile number *</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="Phone number"
                      // value="+91 9852 8855 252"
                    />
                    {/* <input type="tel" id="mobile_number" name="mobile_number" pattern="[0-9]{10}" required /> */}
                  </div>

                  <div class=" text-[17px] mt-4 text-gray-700 ">
                    <label for="inputEmail4" class="font-semibold ">
                      Email *
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="inputEmail4"
                      // value="example@homerealty.com"
                    />
                    {/* <input type="email" id="email" name="email" required /> */}
                  </div>

                  <div className=" text-[17px] mt-4 text-gray-700 ">
                    <label className=" font-semibold  ">Gender</label>
                    <select
                      name="gender"
                      value={userInfo.gender}
                      onChange={handleChange}
                      className=" w-full p-2 border rounded shadow focus:outline-none focus:ring focus:border-blue-400 mb-1 "
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="mt-4 mx-8 flex ">
            <div className="">
              <button
                type="submit"
                className="cart-btn2 py-2.5 px-16 border-2 border-black rounded focus:outline-none font-semibold text-[20px] mr-1 "
                onClick={save}
              >
                SAVE
              </button>
            </div>
            <div className="">
              <button
                type="submit"
                className="cart-btn3 py-2.5 px-16 border-2 border-black rounded focus:outline-none font-semibold text-[20px] ml-1 "
                onClick={edit}
              >
                Edit
              </button>
            </div>
          </div>
        </div>

        <div className=" w-1/3 md:p-10 ">
          <div className=" ">
            <h3 className=" mb-6 font-semibold text-[34px] md:my-4 ">Address</h3>
          </div>
          <form className=" md:mb-10 " onSubmit={handleSubmit}>
            <div class=" text-[17px] mt-9 text-gray-700 ">
              <label className=" font-semibold " htmlFor="street">
                Street Address:
              </label>
              <input
                type="text"
                class="form-control"
                id="street"
                name="street"
                value={address.street}
                onChange={handleInputChange}
              />
            </div>
            <div class=" text-[17px] mt-3 text-gray-700 ">
              <label className=" font-semibold " htmlFor="city">
                City:
              </label>
              <input
                type="text"
                class="form-control"
                id="city"
                name="city"
                value={address.city}
                onChange={handleInputChange}
              />
            </div>
            <div class=" text-[17px] mt-3 text-gray-700 ">
              <label className=" font-semibold " htmlFor="state">
                State:
              </label>
              <input
                type="text"
                class="form-control"
                id="state"
                name="state"
                value={address.state}
                onChange={handleInputChange}
              />
            </div>
            <div class=" text-[17px] mt-3 text-gray-700 ">
              <label className=" font-semibold " htmlFor="postalCode">
                Postal Code:
              </label>
              <input
                type="text"
                class="form-control"
                id="postalCode"
                name="postalCode"
                value={address.postalCode}
                onChange={handleInputChange}
              />
            </div>
            <div class=" text-[17px] mt-3 text-gray-700 ">
              <label className=" font-semibold " htmlFor="country">
                Country:
              </label>
              <input
                type="text"
                class="form-control"
                id="country"
                name="country"
                value={address.country}
                onChange={handleInputChange}
              />
            </div>
          </form>

          <div className="">
            <p className=" font-semibold text-[20px] text-gray-700 ">
              Here are your saved addresses:{" "}
            </p>
          </div>
          <div className="">
            <p className="">
              8808 Ave Dermentum, Onsectetur Adipiscing Tortor Sagittis, CA
              880986, United States
            </p>
          </div>
          <div className="">
            <p className="">
              9087S Divamus Faucibus Str., City name, Postal Code, CA 90896
              United States
            </p>
          </div>
          <div className=" mt-6 ">
            <h3 className=" font-semibold text-[20px] text-gray-700 mb-3 ">
              Phone No's:{" "}
            </h3>
          </div>
          <div className="">
            <p className=" !mb-3 ">(+84) 90 12345</p>
          </div>
          <div className="">
            <p className="">(+84) 90 12345</p>
          </div>
        </div>
      </div>

      <div className=" !bg-white p-4 m-10 mb-20 ">
        <div className=" flex justify-between ">
          <div className=" font-semibold text-[20px] ">
            <p className="">Change Password</p>
            <p className="">*********</p>
          </div>
          <div className=" mt-3 ">
            <button
              type="submit"
              className="cart-btn4 py-1.5 px-12 border-1 border-black rounded focus:outline-none font-semibold text-[17px] ml-1 "
              // onClick={edit}
            >
              CHANGE
            </button>
          </div>
        </div>

        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

        <div className=" flex justify-between ">
          <div className=" ">
            <p className=" font-semibold text-[20px] ">Delete Account</p>
            <p className=" text-gray-500 ">NOTE: Account will NOT BE RECOVERABLE once deleted.</p>
          </div>
          <div className=" mt-3 ">
            <button
              type="submit"
              className="cart-btn4 py-1.5 px-12 border-1 border-black rounded focus:outline-none font-semibold text-[17px] ml-1 "
              // onClick={confirm}
            >
              CONFIRM
            </button>
          </div>
        </div>
        

      </div>

      <Footer />
    </div>
  );
};

export default UserProfile;
