import "./ShopByCategory.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import img1 from "./tshirt.png";
import img2 from "./blouse.png";
import img3 from "./dress.png";
import img4 from "./leggins.png";
import img5 from "./shorts.png";
import img6 from "./skirt.png";
import img7 from "./jumpsuit.png";

import { button } from "react-router-dom";
const ShopByCategory = ({ filterWomenData, CategoryWise }) => {
  // const [data2, setData2] = useState([
  //   {
  //     id: 1,
  //     categoryName: "Bottom Jeans",
  //     description: null,
  //     status: 1,
  //     slug: "bottom-jeans",
  //     flag: 0,
  //     list_order: 1,
  //     createdAt: "2023-11-06T02:48:23.000Z",
  //     updatedAt: "2023-11-06T02:48:23.000Z",
  //   },
  //   {
  //     id: 2,
  //     categoryName: "T Shirt",
  //     description: null,
  //     status: 1,
  //     slug: "t-shirt",
  //     flag: 0,
  //     list_order: 1,
  //     createdAt: "2023-11-06T02:48:30.000Z",
  //     updatedAt: "2023-11-06T02:48:30.000Z",
  //   },
  // ]);
  const [data2, setData2] = useState([
    {
      id: 3,
      icon: img2,
      categoryName: "Blouse",
      description: null,
      status: 1,
      slug: "blouse",
      flag: 0,
      list_order: 1,
      createdAt: "2024-01-13T16:52:22.000Z",
      updatedAt: "2024-01-13T16:52:22.000Z",
    },
    {
      id: 4,
      icon: img1,
      categoryName: "Shirt & Jacket",
      description: null,
      status: 1,
      slug: "shirt-and-jacket",
      flag: 0,
      list_order: 1,
      createdAt: "2024-01-13T16:52:31.000Z",
      updatedAt: "2024-01-13T16:52:31.000Z",
    },
    {
      id: 5,
      icon: img3,
      categoryName: "Dress",
      description: null,
      status: 1,
      slug: "dress",
      flag: 0,
      list_order: 1,
      createdAt: "2024-01-13T16:52:37.000Z",
      updatedAt: "2024-01-13T16:52:37.000Z",
    },
    {
      id: 6,
      icon: img4,
      categoryName: "Pants",
      description: null,
      status: 1,
      slug: "pants",
      flag: 0,
      list_order: 1,
      createdAt: "2024-01-13T16:52:41.000Z",
      updatedAt: "2024-01-13T16:52:41.000Z",
    },
    {
      id: 7,
      icon: img6,
      categoryName: "Skirts",
      description: null,
      status: 1,
      slug: "skirts",
      flag: 0,
      list_order: 1,
      createdAt: "2024-01-13T16:52:49.000Z",
      updatedAt: "2024-01-13T16:52:49.000Z",
    },
    {
      id: 8,
      icon: img7,
      categoryName: "Co-ord Sets",
      description: null,
      status: 1,
      slug: "co-ord-sets",
      flag: 0,
      list_order: 1,
      createdAt: "2024-01-13T16:52:58.000Z",
      updatedAt: "2024-01-13T16:52:58.000Z",
    },
  ]);

  useEffect(() => {
    function product() {
      // make api call
      axios
        .get("https://ecom.urbankarmagb.co.uk/product/categories")
        //  k response
        .then((k) => {
          setData2(k.data.categories);
          console.log(setData2(k["categories"]));
          console.log(k.data.categories);
          console.log("Start");
          console.log(k);
          // console.log(setData2);
        })
        .catch((l) => {
          console.log("Error Found");
        });
    }
    // product();
  }, []);

  const filteredData = filterWomenData;
  return (
    <>
      <div className="shop-main-head">
        <div className="shop-container">
          {/* ::before */}
          <div className="div-text-white">
            <h2 className="shopbycategory-text">
              <span>THE SHOP</span>
            </h2>
          </div>
          <div className="dark-line"></div>
        </div>

        {/* ::after */}
        <div className=" category-grid ">
          {data2.map((prod) => {
            return (
              <>
                <Link to={`/category/${prod["slug"]}`}>
                  <div onClick={() => filteredData("topsandshirts")}>
                    <img color="white" src={prod["icon"]} alt="" />
                    {/* <Link to={`/category/${prod["slug"]}`}> */}
                    <button id={prod["slug"]}>{prod["categoryName"]}</button>
                    {/* </Link> */}
                  </div>
                </Link>
              </>
            );
          })}

          {/* <div onClick={() => filteredData("topsandshirts")}>
            <img src={img1} alt="" />
            <Link to="/ProductDetailPage">
              <button>Tops & T-shirts</button>
            </Link>
          </div> */}

          {/* <div
            onClick={() => {
              filteredData("shirtsandblouse");
            }}
          >
            <img src={img2} alt="" />
            <button>Shirts & Blouses</button>
          </div>
          <div
            onClick={() => {
              filteredData("dresses");
            }}
          >
            <img src={img3} alt="" />
            <button>Dresses</button>
          </div>
          <div
            onClick={() => {
              filteredData("trousers");
            }}
          >
            <img src={img4} alt="" />
            <button>Trousers</button>
          </div>
          <div
            onClick={() => {
              filteredData("shorts");
            }}
          >
            <img src={img5} alt="" />
            <button>Shorts</button>
          </div>
          <div
            onClick={() => {
              filteredData("skirts");
            }}
          >
            <img src={img6} alt="" />
            <button>Skirts</button>
          </div>
          <div
            onClick={() => {
              filteredData("jumpsuits");
            }}
          >
            <img src={img7} alt="" />
            <button>Jumpsuits</button>
          </div>
          <div
            onClick={() => {
              filteredData("knitwear");
            }}
          >
            <img src={img2} alt="" />
            <button>Knitwear</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ShopByCategory;
