import { createSlice } from "@reduxjs/toolkit";

const initialCartState = {
    success: false, 
    cartProductVariants: [],
};

const cartSlice = createSlice({
    name: "cart",
    initialState: initialCartState,
    reducers: {
        setCartProductVariants(state, action) {
            state.cartProductVariants = action.payload;
        },
        removerCartProductVariant(state, action) {
            state.cartProductVariants = state.cartProductVariants.filter(
                (item) => item.id !== action.payload
            );
        },
        updateCartProductVariantQuantity(state, action) {
            const { id, quantity } = action.payload;
            const cartProductVariant = state.cartProductVariants.find(
                (item) => item.id === id
            );
            cartProductVariant.quantity = quantity;
        }
    },
});

export const { setCartProductVariants, removerCartProductVariant, updateCartProductVariantQuantity } = cartSlice.actions;
export default cartSlice.reducer;