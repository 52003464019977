import React from "react";
import { useState, useEffect } from "react";
import img1 from "./wishlist.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";
// import package_svg from "./package.svg";
import package_svg from "../ProgressSteps/package.svg";
import { useSelector } from "react-redux";
// import ItemCard from "../ItemCard";
import WishlistCard from "../WishlistCard";

const WishList = () => {
  const [wishlistProductVariants, setWishlistProductVariants] = useState([]);

  useEffect(() => {
    axios
      .get(`https://ecom.urbankarmagb.co.uk/users/wishlist`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((k) => {
        console.log(k.data);
        setWishlistProductVariants(k.data.wishlists);
        console.log("fetchedWishlistData- ");
        // console.log(fetchedAddressData[0].firstname);
        console.log(wishlistProductVariants);
      })
      .catch((l) => {
        console.log("Error Found");
        console.log(l);
      });
  }, []);
  return (
    <div>
      <div className=" rounded-xl p-8">
        {wishlistProductVariants.length > 0 ? (
          <div className="p-0">
            <div className="text-center row">
              <h2>
                WishList ({wishlistProductVariants.length}{" "}
                {wishlistProductVariants.length === 1 ? `item` : `items`}){" "}
              </h2>
            </div>
            <div className="row">
              <div className="col-10 me-12 ml-5">
                {/* <div className="row">
                  <div className="bg-light border-1 rounded me-4 small-font-css">
                    <img
                      className="m-2"
                      src={package_svg}
                      style={{ display: "inline" }}
                      width={25}
                      height={25}
                      alt="package_logo"
                    />
                    Hurrah! You're saving on handling fees!
                  </div>
                </div> */}
                <div className="row mt-2">
                  {/* <h4 className="my-2">
                    {" "}
                    Whishlist: ({wishlistProductVariants.length}{" "}
                    {wishlistProductVariants.length === 1 ? `item` : `items`}){" "}
                  </h4> */}
                </div>
                <div className="row">
                  {wishlistProductVariants.map((cartProductVariant) => (
                    <WishlistCard
                      index={cartProductVariant.id}
                      title={cartProductVariant.productDetails?.name}
                      description={
                        cartProductVariant.productDetails?.description
                      }
                      // size={cartProductVariant.cartVariant?.size}
                      // quantity={cartProductVariant.quantity}
                      salePrice={cartProductVariant.productDetails?.salePrice}
                      discountedPrice={
                        cartProductVariant.productDetails?.discountedPrice
                      }
                      itemId={cartProductVariant.id}
                      // maxQuantity={cartProductVariant.cartVariant?.quantity}
                      coverImage={cartProductVariant.productDetails?.coverImage}
                      slug={cartProductVariant.productDetails?.slug}
                    />
                  ))}
                </div>
              </div>
              {/* <div className="col-4">
                <div className="row">
                  <div className="bg-light border-1 rounded small-font-css">
                    <img
                      src={shield_svg}
                      className="m-2"
                      style={{ display: "inline" }}
                      width={25}
                      height={25}
                      alt="shield_logo"
                    />
                    Purchase Protection Enabled
                  </div>
                </div>
                <div className="row mt-2">
                  <h4 className="my-2 ps-0"> Proceed Here:</h4>
                </div>
                <div className="row">
                  <OrderSummary
                    btnAction={nextStep}
                    netValue={cartProductVariants.reduce(
                      (acc, cartProductVariant) =>
                        acc +
                        cartProductVariant.cartVariant?.productItem
                          ?.discountedPrice *
                          cartProductVariant.quantity,
                      0
                    )}
                  />
                </div>
                <div className="row mt-3"></div>
              </div> */}
            </div>
          </div>
        ) : (
          <div>
            <Wish>
              <h2 className=" font-bold ">WishList</h2>
              <img src={img1} alt="" />
              <h3>It feels so Light!</h3>
              <p>There is nothing in your wishlist! Let's add some products.</p>
              <Link to="/women">
                <button>SHOP NOW</button>
              </Link>
            </Wish>
          </div>
        )}
      </div>
    </div>
  );
};

export default WishList;

const Wish = styled.div`
  margin: 80px 0;
  h2 {
    letter-spacing: 5px;
    font-size: 2.5rem;
    font-weight: bold;
  }
  h3 {
    letter-spacing: 3px;
  }
  p {
    letter-spacing: 2px;
    text-align: center;
  }
  img {
    min-width: 50px;
    width: 8%;
  }
  button {
    padding: 10px 40px;
    background-color: black;
    color: white;
    border: 1px solid black;
    transition: all 0.3s ease-in-out;
  }
  button:hover {
    background-color: white;
    color: black;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media (max-width: 768px) {
    h2 {
      font-size: 1.8rem;
    }
    h3 {
      font-size: 1.2rem;
    }
  }
`;
