import { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./Pages/LandingPage/LandingPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Accessories from "./Pages/Accessories/Accessories";
import CartPage from "./Pages/CartPage/CartPage";
import RegisterPage from "./Pages/RegisterPage/RegisterPage";
import WomenPage from "./Pages/WomenPage/WomenPage";
import CheckoutPage from "./Pages/CheckoutPage/CheckoutPage";
import AboutPage from "./Pages/Aboutpage/AboutPage";
import WishListPage from "./Pages/WishListPage/WishListPage";
import SuccessPage from "./Pages/OrderPage/SuccessPage";
import ProductDetailPage from "./Components/Details/ProductDetailPage";
import FailedPage from "./Pages/OrderPage/FailedPage";
import OrderDetails from "./Pages/OrderPage/OrderDetails";
import TrackingPage from "./Pages/OrderPage/TrackingPage";
import CheckoutSummary from "./Pages/OrderPage/CheckoutSummary";
import Account from "./Components/UserAccount/Account";
import UserProfile from "./Components/UserAccount/UserProfile";
import Orders from "./Pages/OrderPage/Orders";
import PrivacyPolicy from "./Pages/PolicyPages/PrivacyPolicy";
import ReturnRefund from "./Pages/PolicyPages/ReturnRefund";
import ShipmentPolicy from "./Pages/PolicyPages/ShipmentPolicy";
import TermsConditions from "./Pages/PolicyPages/TermsConditions";
import { CartProvider } from "./Context/notes/CartContext";
import CategorySlug from "./Pages/CategoryPage/CategorySlug";
import React  from 'react';

function App() {
  const ProductData = [
    {
      id: 1,
      img: "https://cdn11.bigcommerce.com/s-wek9ye9/images/stencil/1280x1280/products/124/521/bfb56c35-1919-4407-ac3d-b7868789da08__63393.1514177785.jpg?c=2",
      category: "DRESS",
      content: "Naminos selementum an metropolis",
      olderPrice: "320.00",
      newPrice: "280.00",
      quantity: "45",
    },
    {
      id: 2,
      img: "https://cdn11.bigcommerce.com/s-wek9ye9/images/stencil/1280x1280/products/124/521/bfb56c35-1919-4407-ac3d-b7868789da08__63393.1514177785.jpg?c=2",
      category: "DRESS",
      content: "Naminos selementum an metropolis",
      olderPrice: "320.00",
      newPrice: "280.00",
      quantity: "24",
    },
    {
      id: 3,
      img: "https://cdn11.bigcommerce.com/s-wek9ye9/images/stencil/1280x1280/products/124/521/bfb56c35-1919-4407-ac3d-b7868789da08__63393.1514177785.jpg?c=2",
      category: "DRESS",
      content: "Naminos selementum an metropolis",
      olderPrice: "320.00",
      newPrice: "280.00",
      quantity: "14",
    },
    {
      id: 4,
      img: "https://cdn11.bigcommerce.com/s-wek9ye9/images/stencil/1280x1280/products/124/521/bfb56c35-1919-4407-ac3d-b7868789da08__63393.1514177785.jpg?c=2",
      category: "DRESS",
      content: "Naminos selementum an metropolis",
      olderPrice: "320.00",
      newPrice: "280.00",
      quantity: "12",
    },
    {
      id: 5,
      img: "https://cdn11.bigcommerce.com/s-wek9ye9/images/stencil/1280x1280/products/124/521/bfb56c35-1919-4407-ac3d-b7868789da08__63393.1514177785.jpg?c=2",
      category: "DRESS",
      content: "Naminos selementum an metropolis",
      olderPrice: "320.00",
      newPrice: "280.00",
      quantity: "34",
    },
    {
      id: 6,
      img: "https://cdn11.bigcommerce.com/s-wek9ye9/images/stencil/1280x1280/products/124/521/bfb56c35-1919-4407-ac3d-b7868789da08__63393.1514177785.jpg?c=2",
      category: "DRESS",
      content: "Naminos selementum an metropolis",
      olderPrice: "320.00",
      newPrice: "280.00",
      quantity: "35",
    },
  ];
  const [cartItems, setCartItems] = useState([]);
  const handleAddProduct = (item) => {
    setCartItems([...cartItems, item]);
    console.log("items- ");
    console.log(item);
  };
  useEffect(() => {
    console.log("cart Items- ");
    console.log(cartItems);
  }, [cartItems]);

  return (
    <>
      <CartProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<LandingPage totalItems={cartItems.total_items} />}
            />
            <Route
              path="#"
              element={
                <Accessories
                  products={ProductData}
                  onAddToCart={handleAddProduct}
                />
              }
            />
            <Route path="/cart" element={<CartPage cartItems={cartItems} />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/women"
              element={<WomenPage onAddToCart={handleAddProduct} />}
            />

            {/* Category dikhega  */}
            <Route
              exact={true}
              path="/category/:categoryName"
              element={<CategorySlug />}
            />
            <Route
              exact={true}
              path="/product/:name"
              element={<ProductDetailPage />}
            />
            {/* Product dikhega 
          // /product/:product-slug */}
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/wishlist" element={<WishListPage />} />
            <Route path="/Account" element={<Account />} />
            <Route path="/SucessPage" element={<SuccessPage />} />
            <Route path="FailedPage" element={<FailedPage />} />

            <Route path="/OrderDetails" element={<OrderDetails />} />
            <Route path="/TrackingPage" element={<TrackingPage />} />
            <Route path="/CheckoutSummary" element={<CheckoutSummary />} />
            <Route path="/UserProfile" element={<UserProfile />} />
            <Route path="/Orders" element={<Orders />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/ReturnRefund" element={<ReturnRefund />} />
            <Route path="/ShipmentPolicy" element={<ShipmentPolicy />} />
            <Route path="/TermsConditions" element={<TermsConditions />} />
          </Routes>
        </BrowserRouter>
      </CartProvider>
    </>
  );
}

export default App;
