import React from "react";
import Checkout from "../../Components/Checkout/Checkout";
import AddressForm from "../../Components/Checkout/AddressForm";
import Header from "../../Components/Header/Header";

const CheckoutPage = () => {
  return (
    <>
      <Header />
      <AddressForm />
      {/* <Checkout /> */}
    </>
  );
};

export default CheckoutPage;
