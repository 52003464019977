// reducer imports
import { removerCartProductVariant } from "../Cart/cartReducer";
import { updateCartProductVariantQuantity } from "../Cart/cartReducer";

// client imports
import { updateCartItemQuantity } from "../../Services/client";

// import { useDispatch } from "react-redux";
import { useState } from "react";
import { removeCartItem } from "../../Services/client";
import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";

import "./WishlistCard.css";
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function ItemCard({
  itemId,
  title,
  description,
  // size,
  // quantity,
  salePrice,
  discountedPrice,
  // maxQuantity,
  coverImage,
  slug,
}) {
  // const dispatch = useDispatch();
  const [product, setProduct] = useState({
    itemId: itemId,
    title: title,
    description: description,
    salePrice: salePrice,
    discountedPrice: discountedPrice,
    coverImage: coverImage,
    slug: slug,
  });
  const navigate = useNavigate();
  const [seed, setSeed] = useState(1);
  // const [currentQuantity, setCurrentQuantity] = useState(quantity);
  const [isLoading, setIsLoading] = useState(false);

  const handleMoveToCart = async () => {
    handleRemoveItem();
    navigate(`/product/${slug}`);
  };
  const handleRemoveItem = async () => {
    axios
      .delete(`https://ecom.urbankarmagb.co.uk/users/wishlist/${itemId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((k) => {
        // setSeed(Math.random());
        window.location.reload();
        console.log(k);
        // handleItemLoad();
      })
      .catch((l) => {
        console.log(l);
      });
  };

  return (
    <div className="bg-light border-1 p-2 rounded margin-container my-2">
      <div className="row">
        <div className="col-2">
          <img
            src={product.coverImage}
            alt="dress"
            className="m-1"
            style={{ width: "100px", height: "140px", objectFit: "cover" }}
          />
        </div>
        <div className="col-10 my-1 d-flex flex-column justify-content-between">
          <div>
            <div className="row">
              <div className="d-flex ps-0 justify-content-between">
                <div className="font-weight-bold">{product.title}</div>
                <div className="d-flex align-items-center gap-2">
                  <span className="text-decoration-line-through text-secondary">
                    £{product.salePrice.toFixed(2)}
                  </span>
                  <span className="text-danger">
                    £{product.discountedPrice.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <div className="row text-sm mt-4"> {product.description}</div>
            {/* <div className="row">
              <div className="d-flex align-items-center ps-0">
                Quantity:
                <button
                  disabled={currentQuantity === 1}
                  onClick={() => handleQuantityChange(-1)}
                  className="btn btn-sm custom-icon-btn"
                >
                  <BiMinusCircle />
                </button>
                <span>{currentQuantity}</span>
                <button
                  onClick={() => handleQuantityChange(1)}
                  disabled={currentQuantity === maxQuantity}
                  className="btn btn-sm custom-icon-btn"
                >
                  <BiPlusCircle />
                </button>
              </div>
            </div> */}
          </div>

          {/* <hr style={{ marginLeft: "-.75rem" }} /> */}

          <div className="d-flex justify-content-between">
            <div>
              <button
                className="btn btn-dark btn-sm me-2 rounded-pill"
                style={{ marginLeft: "-0.75rem" }}
                onClick={handleMoveToCart}
              >
                MOVE TO CART
              </button>
            </div>
            {/* {currentQuantity != quantity && (
              <div>
                <button
                  style={{ background: "rgb(50,190,166)", color: "white" }}
                  className="btn btn-sm rounded-pill"
                  onClick={handleSubmitQuantityChange}
                >
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  ) : (
                    "Update Cart"
                  )}
                </button>
              </div>
            )} */}
            <div>
              <button
                className="justify-content-end btn btn-dark btn-sm rounded-pill"
                onClick={handleRemoveItem}
              >
                REMOVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
