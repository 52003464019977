import React, { useEffect, useState } from "react";
import styled from "styled-components";

import package_svg from "./package.svg";
import shield_svg from "./shield.svg";

import { useSelector } from "react-redux";
import ItemCard from "../ItemCard";
import "./ProgressSteps.css";

import OrderSummary from "../OrderSummary";
import AddressForm from "../AddressForm";
import SavedAddressCard from "../SavedAddressCard";
import CheckoutSection from "../CheckoutSection";
import LoginModal from "../LoginModal/LoginModal";
import LoginBox from "../LoginBox";
import { act } from "react-dom/test-utils";

const MainContainer = styled.div`
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
  padding: 0 16px;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: #f3e7f3;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: #4a154b;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid
    ${({ step }) => (step === "completed" ? "#4A154B" : "#F3E7F3")};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 19px;
  color: #f3e7f3;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span`
  font-size: 19px;
  color: #4a154b;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;
`;

const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: #4a154b;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
  width: 90px;
  :active {
    transform: scale(0.98);
  }
  :disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
`;

const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: #4a154b;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

const steps = [
  {
    label: "Login/Signup",
    step: 1,
  },
  {
    label: "Bag",
    step: 2,
  },
  {
    label: "Delivery",
    step: 3,
  },
  {
    label: "Checkout",
    step: 4,
  },
];

const devMode = "inactive";

const auth_token = localStorage.getItem("token");

const ProgressSteps = () => {
  const [activeStep, setActiveStep] = useState(1);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (auth_token && activeStep === 1) {
      nextStep();
    }
  }, [auth_token, activeStep]);

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  const cartProductVariants = useSelector(
    (state) => state.cartReducer.cartProductVariants
  );
  return (
    <>
      <MainContainer>
        <StepContainer width={width}>
          {steps.map(({ step, label }) => (
            <StepWrapper key={step}>
              <StepStyle step={activeStep >= step ? "completed" : "incomplete"}>
                {activeStep > step ? (
                  <CheckMark>L</CheckMark>
                ) : (
                  <StepCount>{step}</StepCount>
                )}
              </StepStyle>
              <StepsLabelContainer>
                <StepLabel key={step}>{label}</StepLabel>
              </StepsLabelContainer>
            </StepWrapper>
          ))}
        </StepContainer>
        <div className="row">
          {/* Step 1: */}
          {activeStep === 1 && !auth_token && (
            <LoginBox handleNext={nextStep} />
          )}

          {/* // Step 2: */}
          {activeStep === 2 && (
            <div className="p-0">
              <div className="text-center row">
                <h2>Shopping Cart</h2>
              </div>
              <div className="row">
                <div className="col-7 me-4">
                  <div className="row">
                    <div className="bg-light border-1 rounded me-4 small-font-css">
                      <img
                        className="m-2"
                        src={package_svg}
                        style={{ display: "inline" }}
                        width={25}
                        height={25}
                        alt="package_logo"
                      />
                      Hurrah! You're saving on handling fees!
                    </div>
                  </div>
                  <div className="row mt-2">
                    <h4 className="my-2">
                      {" "}
                      Shopping Bag: ({cartProductVariants.length}{" "}
                      {cartProductVariants.length === 1 ? `item` : `items`}){" "}
                    </h4>
                  </div>
                  <div className="row">
                    {cartProductVariants.map((cartProductVariant) => (
                      <ItemCard
                        index={cartProductVariant.id}
                        title={
                          cartProductVariant.cartVariant?.productItem?.name
                        }
                        size={cartProductVariant.cartVariant?.size}
                        quantity={cartProductVariant.quantity}
                        salePrice={
                          cartProductVariant.cartVariant?.productItem?.salePrice
                        }
                        discountedPrice={
                          cartProductVariant.cartVariant?.productItem
                            ?.discountedPrice
                        }
                        itemId={cartProductVariant.id}
                        productId={cartProductVariant.cartVariant?.productId}
                        slug={cartProductVariant.cartVariant?.productItem?.slug}
                        maxQuantity={cartProductVariant.cartVariant?.quantity}
                        coverImage={
                          cartProductVariant.cartVariant?.productItem
                            ?.coverImage
                        }
                      />
                    ))}
                  </div>
                </div>
                <div className="col-4">
                  <div className="row">
                    <div className="bg-light border-1 rounded small-font-css">
                      <img
                        src={shield_svg}
                        className="m-2"
                        style={{ display: "inline" }}
                        width={25}
                        height={25}
                        alt="shield_logo"
                      />
                      Purchase Protection Enabled
                    </div>
                  </div>
                  <div className="row mt-2">
                    <h4 className="my-2 ps-0"> Proceed Here:</h4>
                  </div>
                  <div className="row">
                    <OrderSummary
                      btnAction={nextStep}
                      netValue={cartProductVariants.reduce(
                        (acc, cartProductVariant) =>
                          acc +
                          cartProductVariant.cartVariant?.productItem
                            ?.discountedPrice *
                            cartProductVariant.quantity,
                        0
                      )}
                    />
                  </div>
                  <div className="row mt-3"></div>
                </div>
              </div>
            </div>
          )}

          {/* Step 3: */}
          {activeStep === 3 && (
            <div>
              <h2 className="text-center">Select a delivery address</h2>
              <p className="mb-2 text-sm text-center">
                Select from one of the previous addresses or add a new address
                which will be saved for future deliveries!
              </p>
              <div className="row">
                <div className="col-6">
                  <h4 className="text-center">SHIP TO SAVED ADDRESS</h4>
                  <SavedAddressCard handleNext={nextStep} />
                </div>
                <div className="col-6">
                  <h4 className="text-center">SHIP TO NEW ADDRESS</h4>
                  <AddressForm handleClick={nextStep} />
                </div>
              </div>
            </div>
          )}

          {/* Step 4: */}
          {activeStep === 4 && (
            <div className="row">
              <CheckoutSection handleBack={prevStep} />
            </div>
          )}
          {devMode === "active" && (
            <div className="row">
              <ButtonsContainer>
                <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
                  Previous
                </ButtonStyle>
                <ButtonStyle
                  onClick={nextStep}
                  disabled={activeStep === totalSteps}
                >
                  Next
                </ButtonStyle>
              </ButtonsContainer>
            </div>
          )}
        </div>
      </MainContainer>
    </>
  );
};

export default ProgressSteps;
