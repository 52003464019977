import React from "react";

const PrivacyPolicy = () => {
   return(
    <div>
        
    </div>
   )
}

export default PrivacyPolicy;