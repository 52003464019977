import React, { createContext, useState } from "react";
import "./Account.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import UserProfile from "./UserProfile";
import Orders from "../../Pages/OrderPage/Orders";
import SuccessPage from "../../Pages/OrderPage/SuccessPage";
import WishList from "../WishList/WishList";
import ProductDetailPage from "../Details/ProductDetailPage";
import TrackingPage from "../../Pages/OrderPage/TrackingPage";
import { AiOutlineRight } from "react-icons/ai";
import { FaStarOfLife } from "react-icons/fa";
import { GiReturnArrow } from "react-icons/gi";
import DropdownMenu from "../DropdownMenu";

const Account = () => {
  const [activeComponent, setActiveComponent] = useState("");
  const renderComponent = () => {
    if (activeComponent === "profile") {
      return <UserProfile />;
    } else if (activeComponent === "orders") {
      return <Orders />;
    } else if (activeComponent === "product details") {
      return <ProductDetailPage />;
    } else if (activeComponent === "wishlist") {
      return <WishList />;
    } else if (activeComponent === "tracking") {
      return <TrackingPage />;
    }
  };

  return (
    <div>
      <Header />
      {/* <div className="">
            <h4 className=" text-[25px] font-semibold mb-6 ">Personal Center</h4>
          </div> */}

      <div className=" flex justify-center my-20 ">

        <div className=" my-2 mx-4 w-80 ">
          <DropdownMenu
            title="My Account"
            items={["My Profile","My Addresses"]}
            // showProfileIcon={true}
          />

          <DropdownMenu title="My Orders" className="cursor-pointer" items={["All Orders", "Recent Orders"]} />

          <DropdownMenu
            title="Policy"
            items={["Return Policy", "Privacy Policy", "Shipping Info"]}
          />

          <DropdownMenu
            title="Customer Service"
            items={["Call", "Resolve in Chat"]}
          />
        </div>

        <div className=" w-2/5 ">

        <div className=" !bg-white p-4 mx-1 my-2 ">
          <div className=" flex justify-between ">
            <div className=" ">
              {" "}
              <p className=" font-semibold text-[22px] ">Hii, User</p>{" "}
            </div>
            <div className=" flex items-center cursor-pointer " >
              {" "}
              <a className=" text-gray-500 mb-0 mr-2 font-semibold " href="/UserProfile">
               My Profile
              </a>
              {/* onClick={() => setActiveComponent('profile')} */}
              {/* <p className=" text-gray-500 mb-0 mr-2 font-semibold ">
               My Profile </p>{" "} */}
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </div>

          <div className=" flex justify-evenly mt-8 ">
            <div className=" text-center ">
              <p className=" text-black ">***</p>
              <p className=" text-gray-600 ">Coupons</p>
            </div>
            <div className="">
            <div className=" text-center ">
              <p className="">***</p>
              <p className=" text-gray-600 ">Wallet</p>
            </div>
            </div>
          </div>
        </div>

        <div className=" !bg-white p-4 mx-1 my-2.5 ">
        <div className=" flex justify-between ">
            <div className="">
              {" "}
              <p className=" font-semibold text-[22px] ">My Orders</p>{" "}
            </div>
            <div className=" flex items-center cursor-pointer ">
              {" "}
              <a className=" text-gray-500 mb-0 mr-2 font-semibold " href="/Orders">
                View All
              </a>
              {/* onClick={() => setActiveComponent('orders')} */}
              {/* <p className=" text-gray-500 mb-0 mr-2 font-semibold ">
                View All </p>{" "} */}
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </div>
          <div className=" mt-6 flex justify-evenly ">
            <div className="  ">
            <img className="w-12 object-contain" src="deliveryimage2.png"/>
            <p className=" text-gray-600 mt-2 ">Shipped</p>
            </div>
            <div className="  flex flex-col items-center ">
            <div className=""><GiReturnArrow/></div>
            <p className=" text-gray-600 mt-3 ">Returns</p>
            </div>
          </div>
        </div>

        </div>

        <div className=" w-80 ">
        <div className=" !bg-white p-4 m-2 ">
          <div className=" ">
            <div className="">
              {" "}
              <p className=" font-semibold text-[19px]">Customer Service</p>{" "}
            </div>
          </div>
          <div className=" text-center mt-10 ">
                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                <p className=" text-gray-600 ">My Message</p>
            </div>
        </div>
        <div className=" !bg-white p-4 m-2 ">
        <div className=" flex justify-between ">
            <div className="">
              {" "}
              <p className=" font-semibold text-[17px] ">Wishlist</p>{" "}
            </div>
            <div className=" flex items-center cursor-pointer ">
              {" "}
              <a className=" text-gray-500 mb-0 mr-2 font-semibold " href="/wishlist">
                More
              </a>
              {/* <p className=" text-gray-500 mb-0 mr-2 font-semibold ">More </p>{" "} */}
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </div>
          <div className=" flex flex-col items-center mt-8 ">
              <img className="w-16" src="heart.png" />
              <p className=" text-[13px] mt-2">You have not saved anything recently</p>
            </div>
        </div>
        </div>

      </div>
      

      {/* onClick={() => setActiveComponent('profile')} */}
      {/* onClick={() => setActiveComponent('orders')} */}

          {/* <div className=" ">               
              <div className="profile-content bg-white w-full rounded-xl ">{renderComponent()}</div>
          </div> */}


      {/* <div className=" flex justify-evenly ">
        <div className=" !bg-white w-1/4 m-3 p-6 ">
          <h3 className="">Personal Center</h3>
          <div className="">
            <div class="dropdown">
              <button
                class="btn dropdown-toggle "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                My Account
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    My Profile
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                  Address Book
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                   Urban Karma VIP
                  </a>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <button
                class="btn dropdown-toggle "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                My Orders
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    Recent Orders
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                  All Orders
                  </a>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <button
                class="btn dropdown-toggle "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Customer Service
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    Resolve on call
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                  Resolve on Text
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className=" flex flex-col !bg-white w-1/2 m-2 p-6 ">
          <div className=" flex justify-between">
            <h3 className="">Hi, shaguninboston21</h3>
            <p className="">My Profile</p>
          </div>
          <div className=" flex justify-around my-8 ">
            <div className="">
              <div className=" flex ">
                <div className="">
                  <FaStarOfLife />
                </div>
                <div className="">
                  <FaStarOfLife />
                </div>
                <div className="">
                  <FaStarOfLife />
                </div>
              </div>
              <div className=" mt-3 text-gray-500 ">Coupons</div>
            </div>
            <div className="">
              <div className=" flex  ">
                <div className="">
                  <FaStarOfLife />
                </div>
                <div className="">
                  <FaStarOfLife />
                </div>
                <div className="">
                  <FaStarOfLife />
                </div>
              </div>
              <div className=" mt-3 text-gray-500 ">Points</div>
            </div>
            <div className="">
              <div className=" flex ">
                <div className="">
                  <FaStarOfLife />
                </div>
                <div className="">
                  <FaStarOfLife />
                </div>
                <div className="">
                  <FaStarOfLife />
                </div>
              </div>
              <div className=" mt-3 text-gray-500 ">Wallet</div>
            </div>
            <div className="">
              <div className=" flex justify-center ">
                <div className="">
                  <HiGiftTop />
                </div>
              </div>
              <div className=" mt-3 text-gray-500 ">Gift card</div>
            </div>
          </div>
        </div>

        <div className=" !bg-white w-1/4 m-2 ">
          <h3 className="">Customer Service</h3>
        </div>
      </div> */}


      {/* <div className=" my-16 font-Roboto ">
        <hr class="w-3/4 h-1 mx-auto my-8 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>

        <div className=" flex mt-10 ">
          <div className=" flex flex-col justify-center bg-white border-gray-200 border-2 w-1/4 text-[22px] ml-16 p-3 rounded-xl min-h-fit max-h-[530px] overflow-y-auto ">
            <div className=" hover:border-2 border-black p-2 px-4 hover:bg-violet-100 cursor-pointer flex justify-between items-center ">
              <a className="text-black " href="/UserProfile">
                Profile
              </a>
              <div className=" ">
                <AiOutlineRight />{" "}
              </div>
            </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 cursor-pointer ">
              <a className="text-black " href="/SucessPage">
                Sucess Page
              </a>
            </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 cursor-pointer ">
              <a className="text-black " href="/FailedPage">
                Failed Page
              </a>
            </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 cursor-pointer ">
              <a className="text-black " href="/ProductDetailPage">
                Product Detail
              </a>
            </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 cursor-pointer flex justify-between items-center ">
              <a className="text-black " href="/Orders">
                Orders
              </a>
              <div className=" ">
                <AiOutlineRight />{" "}
              </div>
              
            </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 cursor-pointer">
              <a className="text-black " href="/OrderDetails">
                Order Detail
              </a>
            </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 cursor-pointer flex justify-between items-center ">
              <a className="text-black " href="/TrackingPage">
                Tracking{" "}
              </a>
              onClick={() => setActiveComponent('tracking')}
              <div className=" ">
                <AiOutlineRight />{" "}
              </div>
            </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 ">
              <a className="text-black " href="/CheckoutSummary">
                Checkout
              </a>
            </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 cursor-pointer flex justify-between items-center " onClick={() => setActiveComponent('wishlist')} >
                <a className="text-black " href="/wishlist">Wishlist</a>
                <div className=" "><AiOutlineRight/> </div> 
                </div>
            <div className=" hover:border-2 border-black p-2 px-3 hover:bg-violet-100 cursor-pointer">
              <a href="/">LogOut</a>
            </div>
          </div>
        </div>

        <hr class="w-3/4 h-1 mx-auto !my-24 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
      </div> */}

      <Footer />
    </div>
  );
};

export default Account;
