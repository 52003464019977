import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const SuccessPage = () => {
    return(
        <div>
            <Header />
            
            <div className=" md:mt-20 md:p-0 p-4 ">
            <i className=" bg-[#F8FAF5] md:w-96 w-60 rounded-full mx-auto flex justify-center text-[#9ABC66] md:text-[150px] text-[100px] ">✓</i>
            </div>
            <div className=" flex justify-center md:p-10 p-6 ">
                <h1 className=" flex text-[50px] font-bold text-[#88B04B] ">SUCCESS</h1>
            </div>
            <div className=" md:mb-24 md:p-0 p-4 ">
                <h2 className="flex justify-center md:text-[30px] text-[14px] text-center ">Your Product has been Successfully Placed.</h2>
                <h5 className=" flex justify-center md:text-[20px] text-[10px] text-center ">You will recieve notification once the item is being shipped.</h5>
            </div>
            
            <Footer />
        </div>
    )
}

export default SuccessPage;