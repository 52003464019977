import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./OrderDetails.css";

const OrderDetails = () => {
  return (
    <div>
      <Header />

      <div className=" my-10 ">
        <div className="">

          <div class="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
            <div class="flex justify-start item-start space-y-2 flex-col !ml-6 ">
              <h1 class="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9 text-[#2c062c] ">
                Order #13432
              </h1>
              <p class="text-base text-black font-medium leading-6">
                4 Oct 2023 at 10:34 PM
              </p>
            </div>
            <div class="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
              <div class="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
                <div class="flex flex-col justify-start items-start !bg-white border-2 border-gray-300 rounded-lg bg-white px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
                  <p class="text-lg md:text-xl text-black font-semibold leading-6 xl:leading-5 text-gray-800">
                    Customer’s Cart
                  </p>
                  <div class="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                    <div class="pb-4 md:pb-8 w-full md:w-40">
                      <img
                        class="w-full hidden md:block"
                        src="https://i.ibb.co/84qQR4p/Rectangle-10.png"
                        alt="dress"
                      />
                      <img
                        class="w-full md:hidden"
                        src="https://i.ibb.co/L039qbN/Rectangle-10.png"
                        alt="dress"
                      />
                    </div>
                    <div class="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0">
                      <div class="w-full flex flex-col justify-start items-start space-y-8">
                        <h3 class="text-xl text-black xl:text-2xl font-semibold leading-6 text-gray-800">
                          Premium Quaility Dress
                        </h3>
                        <div class="flex justify-start items-start flex-col space-y-2">
                          <p class="text-sm text-black leading-none text-gray-800">
                            <span class="dark:text-gray-400 text-gray-300">
                              Style:{" "}
                            </span>{" "}
                            Italic Minimal Design
                          </p>
                          <p class="text-sm text-black leading-none text-gray-800">
                            <span class="dark:text-gray-400 text-gray-300">
                              Size:{" "}
                            </span>{" "}
                            Small
                          </p>
                          <p class="text-sm text-black leading-none text-gray-800">
                            <span class="dark:text-gray-400 text-gray-300">
                              Color:{" "}
                            </span>{" "}
                            Light Blue
                          </p>
                        </div>
                      </div>
                      <div class="flex justify-between space-x-8 items-start w-full">
                        <p class="text-base text-black xl:text-lg leading-6">
                          $36.00{" "}
                          <span class="text-red-300 line-through"> $45.00</span>
                        </p>
                        <p class="text-base text-black xl:text-lg leading-6 text-gray-800">
                          01
                        </p>
                        <p class="text-base text-black xl:text-lg font-semibold leading-6 text-gray-800">
                          $36.00
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 md:mt-0 flex justify-start flex-col md:flex-row items-start md:items-center space-y-4 md:space-x-6 xl:space-x-8 w-full">
                    <div class="w-full md:w-40">
                      <img
                        class="w-full hidden md:block"
                        src="https://i.ibb.co/s6snNx0/Rectangle-17.png"
                        alt="dress"
                      />
                      <img
                        class="w-full md:hidden"
                        src="https://i.ibb.co/BwYWJbJ/Rectangle-10.png"
                        alt="dress"
                      />
                    </div>
                    <div class="flex justify-between items-start w-full flex-col md:flex-row space-y-4 md:space-y-0">
                      <div class="w-full flex flex-col justify-start items-start space-y-8">
                        <h3 class="text-xl text-black xl:text-2xl font-semibold leading-6 text-gray-800">
                          High Quaility Italic Dress
                        </h3>
                        <div class="flex justify-start items-start flex-col space-y-2">
                          <p class="text-sm text-black leading-none text-gray-800">
                            <span class="dark:text-gray-400 text-gray-300">
                              Style:{" "}
                            </span>{" "}
                            Italic Minimal Design
                          </p>
                          <p class="text-sm text-black leading-none text-gray-800">
                            <span class="dark:text-gray-400 text-gray-300">
                              Size:{" "}
                            </span>{" "}
                            Small
                          </p>
                          <p class="text-sm text-black leading-none text-gray-800">
                            <span class="dark:text-gray-400 text-gray-300">
                              Color:{" "}
                            </span>{" "}
                            Light Blue
                          </p>
                        </div>
                      </div>
                      <div class="flex justify-between space-x-8 items-start w-full">
                        <p class="text-base text-black xl:text-lg leading-6">
                          $20.00{" "}
                          <span class="text-red-300 line-through"> $30.00</span>
                        </p>
                        <p class="text-base text-black xl:text-lg leading-6 text-gray-800">
                          01
                        </p>
                        <p class="text-base text-black xl:text-lg font-semibold leading-6 text-gray-800">
                          $20.00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center flex-col md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                  <div class="flex flex-col px-4 py-6 !bg-white border-2 border-gray-300 rounded-lg md:p-6 xl:p-8 w-full bg-white space-y-6">
                    <h3 class="text-xl text-black font-semibold leading-5 text-gray-800">
                      Summary
                    </h3>
                    <div class="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                      <div class="flex justify-between w-full">
                        <p class="text-base text-black leading-4 text-gray-800">
                          Subtotal
                        </p>
                        <p class="text-base dark:text-gray-300 leading-4 text-black">
                          $56.00
                        </p>
                      </div>
                      <div class="flex justify-between items-center w-full">
                        <p class="text-base text-black leading-4 text-gray-800">
                          Discount{" "}
                          <span class="bg-gray-200 p-1 text-xs font-medium dark:text-gray-800 leading-3 text-gray-800">
                            STUDENT
                          </span>
                        </p>
                        <p class="text-base dark:text-gray-300 leading-4 text-black">
                          -$28.00 (50%)
                        </p>
                      </div>
                      <div class="flex justify-between items-center w-full">
                        <p class="text-base text-black leading-4 text-gray-800">
                          Shipping
                        </p>
                        <p class="text-base dark:text-gray-300 leading-4 text-black">
                          $8.00
                        </p>
                      </div>
                    </div>
                    <div class="flex justify-between items-center w-full">
                      <p class="text-base text-black font-semibold leading-4 text-gray-800">
                        Total
                      </p>
                      <p class="text-base dark:text-gray-300 font-semibold leading-4 text-black">
                        $36.00
                      </p>
                    </div>
                  </div> 
                  <div class="flex flex-col justify-center !bg-white border-2 border-gray-300 rounded-lg px-4 py-6 md:p-6 xl:p-8 w-full bg-white space-y-6">
                    <h3 class="text-xl text-black font-semibold leading-5 text-gray-800">
                      Shipping
                    </h3>
                    <div class="flex justify-between items-start w-full">
                      <div class="flex justify-center items-center space-x-4">
                        <div class="w-8 h-8">
                          <img
                            class="w-full h-full"
                            alt="logo"
                            src="https://i.ibb.co/L8KSdNQ/image-3.png"
                          />
                        </div>
                        <div class="flex flex-col justify-start items-center">
                          <p class="text-lg leading-6 text-black font-semibold text-gray-800">
                             Delivery
                            <br />
                            <span class="font-normal">
                              Delivery with 24 Hours
                            </span>
                          </p>
                        </div>
                      </div>
                      <p class="text-lg font-semibold leading-6 text-black text-gray-800">
                        $8.00
                      </p>
                    </div>
                    <div class="w-full flex justify-center items-center">
                      <button class=" bg-black hover:!bg-gray-600 !font-Poppins hover:!text-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-4 w-96 md:w-full text-base leading-4 font-bold ">
                        <a href="/TrackingPage" className=" text-2xl font-semibold text-white "> Track Your Order</a>  
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" bg-white  w-full xl:w-96 flex justify-between items-center !bg-white border-2 border-gray-300 rounded-lg md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col">
                <h3 class="text-xl text-black font-semibold leading-5 text-gray-800">
                  Customer
                </h3>
                <div class="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
                  <div class="flex flex-col justify-start items-start flex-shrink-0">
                    <div class="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                      {/* <img
                        src="dress1.jpg"
                        alt="avatar"
                      /> */}
                      <div class="flex justify-start items-start flex-col space-y-2">
                        <p class="text-base text-black font-semibold leading-4 text-left text-gray-800">
                          David Kent
                        </p>
                        <p class="text-base text-black leading-4 text-left text-gray-700">
                          +0123456789
                        </p>
                        {/* <p class="text-sm dark:text-gray-300 leading-5 text-black">
                          10 Previous Orders
                        </p> */}
                      </div>
                    </div>

                    <div class="flex justify-center text-gray-800 text-black md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 7L12 13L21 7"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p class="cursor-pointer text-sm leading-5 mt-2 ">
                        david89@gmail.com
                      </p>
                    </div>
                  </div>
                  <div class="flex justify-between xl:h-full items-stretch w-full flex-col mt-6 md:mt-0">
                    <div class="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row items-center md:items-start">
                      <div class="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8">
                        <p class="text-base text-black font-semibold leading-4 text-center md:text-left text-gray-800">
                          Shipping Address
                        </p>
                        <p class=" !w-full flex md:!justify-start text-center lg:w-full dark:text-gray-300 xl:w-48 text-sm leading-5 text-black">
                          180 North King Street, Northhampton MA 1060
                        </p>
                      </div>
                      <div class="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4">
                        <p class="text-base text-black font-semibold leading-4 text-center md:text-left text-gray-800">
                          Billing Address
                        </p>
                        <p class=" !w-full flex md:justify-start text-center lg:w-full dark:text-gray-300 xl:w-48 text-sm leading-5 text-black">
                          180 North King Street, Northhampton MA 1060
                        </p>
                      </div>
                    </div>
                    {/* <div class="flex w-full justify-center items-center md:justify-start md:items-start">
                      <button class="mt-6 md:mt-0 dark:border-white dark:hover:bg-gray-900 dark:bg-transparent text-black py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base font-medium leading-4 text-gray-800">
                        Edit Details
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <section class="h-100 gradient-custom">
            <div class="container3 py-5 h-100">
              <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-lg-10 col-xl-8">
                  <div class="card" mystyle={"border-radius: 10px;"}>
                    <div className="card-header px-4 py-5 bg-[#add8e6] ">
                      <h5 class="text-muted mb-0">
                        Thanks for your Order,{" "}
                        <span mystyle={"color: #a8729a;"}></span>
                      </h5>
                    </div>
                    <div class="card-body p-4">
                      <div class="d-flex justify-content-between align-items-center mb-4">
                        <p class="lead fw-normal mb-0" mystyle={"color: #a8729a;"}>
                          Receipt
                        </p>
                        <p class="small text-muted mb-0">
                          Receipt Voucher : 0000-####
                        </p>
                      </div>
                      <div class="card shadow-0 border mb-4">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-2">
                              <img
                                src="./dress.jpeg"
                                class="img-fluid"
                                alt="Phone"
                              />
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="fw-bold text-muted mb-0 large ">Dress1</p>
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="text-muted mb-0 medium">White</p>
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="text-muted mb-0 medium">
                                Size: Small
                              </p>
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="text-muted mb-0 medium">Qty: 1</p>
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="text-muted mb-0 medium">$499</p>
                            </div>
                          </div>
                          <hr
                            class="mb-4"
                            mystyle={"background-color: #e0e0e0; opacity: 1;"}
                          />
                          <div class="row d-flex align-items-center">
                            <div class="col-md-2">
                              <p class="text-muted mb-0 medium">Track Order</p>
                            </div>
                            <div class="col-md-10">
                              <div
                                class="progress"
                                mystyle={"height: 6px; border-radius: 16px;"}
                              >
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                   mystyle={"width: 65%; border-radius: 16px; background-color: #a8729a;"}
                                  aria-valuenow="65"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <div class="d-flex justify-content-around mb-1">
                                <p class="text-muted mt-1 mb-0 small ms-xl-5">
                                  Out for delivary
                                </p>
                                <p class="text-muted mt-1 mb-0 small ms-xl-5">
                                  Delivered
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card shadow-0 border mb-4">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-2">
                              <img
                                src="./dress.jpeg"
                                class="img-fluid"
                                alt="Phone"
                              />
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="fw-bold text-muted mb-0 large">Dress2</p>
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="text-muted mb-0 medium">Pink rose</p>
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="text-muted mb-0 medium">
                                Size: Large
                              </p>
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="text-muted mb-0 medium">Qty: 2</p>
                            </div>
                            <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                              <p class="text-muted mb-0 medium">$599</p>
                            </div>
                          </div>
                          <hr
                            class="mb-4"
                            mystyle={"background-color: #e0e0e0; opacity: 1;"}
                          />
                          <div class="row d-flex align-items-center">
                            <div class="col-md-2">
                              <p class="text-muted mb-0 medium">Track Order</p>
                            </div>
                            <div class="col-md-10">
                              <div
                                class="progress"
                                mystyle={"height: 6px; border-radius: 16px;"}
                              >
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  mystyle={"width: 20%; border-radius: 16px; background-color: #a8729a;"}
                                  aria-valuenow="20"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <div class="d-flex justify-content-around mb-1">
                                <p class="text-muted mt-1 mb-0 small ms-xl-5">
                                  Out for delivary
                                </p>
                                <p class="text-muted mt-1 mb-0 small ms-xl-5">
                                  Delivered
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between pt-2">
                        <p class="fw-bold mb-0">Order Details</p>
                        <p class="text-muted mb-0">
                          <span class="fw-bold me-4">Total</span> $898.00
                        </p>
                      </div>

                      <div class="d-flex justify-content-between pt-2">
                        <p class="text-muted mb-0">Invoice Number : 788152</p>
                        <p class="text-muted mb-0">
                          <span class="fw-bold me-4">Discount</span> $19.00
                        </p>
                      </div>

                      <div class="d-flex justify-content-between">
                        <p class="text-muted mb-0">
                          Invoice Date : 2 Oct,2023
                        </p>
                        <p class="text-muted mb-0">
                          <span class="fw-bold me-4">GST 18%</span> 123
                        </p>
                      </div>

                      <div class="d-flex justify-content-between mb-5">
                        <p class="text-muted mb-0">
                          Recepits Voucher : 0000-####
                        </p>
                        <p class="text-muted mb-0">
                          <span class="fw-bold me-4">Delivery Charges</span>{" "}
                          Free
                        </p>
                      </div>
                    </div>
                    <div
                      class="card-footer border-0 px-4 py-5 bg-[#add8e6] " 
                      mystyle={"background-color: #add8e6; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;"}
                    >
                      <h5 class="d-flex align-items-center justify-content-end text-black text-uppercase mb-0">
                        Total paid: <span class="h2 mb-0 ms-2">$1040</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}


      </div>

      <Footer />
    </div>
  );
};

export default OrderDetails;
