import Header from "../../Components/Header/Header";
import axios from "axios";
import "./AddressForm.css";
// import "../Register/Register.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactDom from "react-dom";
import React from "react";

const Register = () => {
  const navigate = useNavigate();

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [country, setCountry] = useState("");
  const [stateName, setStateName] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [phNumber, setPhNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [email1, setEmail1] = useState("");
  const [password1, setPassword1] = useState("");
  const [fetchedAddressData, setFetchedAddressData] = useState([]);
  const addressData = {
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    city: city,
    country: country,
    firstname: fName,
    landmark: landmark,
    lastname: lName,
    phone: phNumber,
    postcode: zipCode,
    state: stateName,
  };
  useEffect(() => {
    axios
      .get(`https://ecom.urbankarmagb.co.uk/users/addresses`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((k) => {
        console.log(k);
        setFetchedAddressData(k.data.addresses);
        console.log("fetchedAddressData- ");
        // console.log(fetchedAddressData[0].firstname);
        console.log(fetchedAddressData);
      })
      .catch((l) => {
        console.log("Error Found");
        console.log(l);
      });
  }, []);

  const handleAddressSelect = () => {
    navigate("/checkoutSummary");
  };
  const handleAddressSave = () => {
    // console.log("button click");
    axios
      .post("https://ecom.urbankarmagb.co.uk/users/address/add", addressData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        // console.log("response");
        console.log(response);
        navigate("/checkoutSummary");
      })
      .catch((err) => {
        console.log("caught error");
        console.log(err);
      });
  };

  return (
    <>
      <div className="wholeAddressPage">
        <div className="addressFormLeft">
          {fetchedAddressData != 0 ? (
            // <div>
            <h2 className="new-account-head" id="reg-left-head">
              Ship To Old Address
            </h2>
          ) : (
            // </div>
            ""
          )}
          {fetchedAddressData != 0
            ? fetchedAddressData.map((item, key) => {
                return (
                  <div>
                    {/* // <div className="addressFormLeft"> */}
                    {/* <div>
                    <h2 className="new-account-head" id="reg-left-head">
                      Ship To Old Address
                    </h2>
                  </div> */}
                    <div></div>
                    <div></div>
                    <br />
                    <br />
                    <div className="fetchedAddressBox">
                      <p id="fetchedAddressBoxClassBoldp">{item.firstname}</p>
                      {/* <br /> */}
                      <p>{item.addressLine1}</p>
                      <p>{item.addressLine2}</p>
                      <p>{item.country}</p>
                      <p id="fetchedAddressBoxClassBoldp">{item.phone}</p>
                      <button
                        className="create-account"
                        onClick={handleAddressSelect}
                      >
                        Deliver to this Address
                      </button>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        <div className="addressForm">
          <div>
            <h2 className="new-account-head">Add New Address</h2>
          </div>
          <div></div>
          {/* <div>
            <hr />
          </div>
          <div>
            <hr />
          </div> */}
          <div>
            <label for="username">First Name</label>
            <br />
            <input
              id="user_first_name"
              name="user[first_name]"
              value={fName}
              onChange={(e) => {
                setFName(e.target.value);
              }}
              size="30"
              type="text"
            />
          </div>

          <div>
            <label for="name">Last Name</label>
            <br />
            <input
              id="user_last_name"
              name="user[last_name]"
              value={lName}
              onChange={(e) => {
                setLName(e.target.value);
              }}
              size="30"
              type="text"
            />
          </div>

          <div>
            <label for="pass2">Address Line 1</label>
            <br />
            <input
              id="addressline_1"
              name="addressline_1"
              value={addressLine1}
              onChange={(e) => {
                setAddressLine1(e.target.value);
              }}
              size="30"
              type="text"
            />
          </div>
          <div>
            <label for="pass2">Address Line 2</label>
            <br />
            <input
              id="addressline_2"
              name="addressline_2"
              value={addressLine2}
              onChange={(e) => {
                setAddressLine2(e.target.value);
              }}
              size="30"
              type="text"
            />
          </div>
          <div>
            <label for="pass2">Country</label>
            <br />
            <input
              id="country"
              name="country"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              size="30"
              type="text"
            />
          </div>
          <div>
            <label for="pass2">State/Province</label>
            <br />
            <input
              id="state"
              name="state"
              value={stateName}
              onChange={(e) => {
                setStateName(e.target.value);
              }}
              size="30"
              type="text"
            />
          </div>
          <div>
            <label for="pass2">Suburb/City</label>
            <br />
            <input
              id="city"
              name="city"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              size="30"
              type="text"
            />
          </div>
          <div>
            <label>Zip/Postcode</label>
            <br />
            <input
              id="zip"
              name="zip"
              value={zipCode}
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
              type="number"
            />
          </div>
          <div>
            <label for="pass2">Landmark</label>
            <br />
            <input
              id="landmark"
              name="landmark"
              value={landmark}
              onChange={(e) => {
                setLandmark(e.target.value);
              }}
              size="30"
              type="text"
            />
          </div>
          <div>
            <label for="phone">Phone number</label>
            <br />
            <input
              type="tel"
              id="phone"
              value={phNumber}
              onChange={(e) => {
                setPhNumber(e.target.value);
              }}
              name="phone"
              //   pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            />
          </div>
          <div></div>
          <div>
            <button
              className="create-account"
              type="submit"
              onClick={handleAddressSave}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
