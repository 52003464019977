import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../LoginModal/loginReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as client from "../../Services/client";

const LoginBox = ({ handleNext }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const loginMe = () => {
    setIsLoading(true);
    setLoginError("");
    client
      .userLogin(email, password)
      .then((res) => {
        dispatch(login(res));
        localStorage.setItem("token", res.token);
        console.log(res);
        // toast.success(response.data.message);
        handleNext();
        // navigate("/");
      })
      .catch((err) => {
        setLoginError("Invalid email/password combination");
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="fluid-container mt-5">
      <div className="row justify-content-center">
        <div className="col-7">
          {loginError && <div className="alert alert-danger">{loginError}</div>}
          <form>
            <div className="form-group email-password mb-3">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                className="form-control input-email-password"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group email-password mb-3">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control input-email-password"
                id="password"
                minLength="8"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="login-register-btn">
              <button
                type="button"
                className="btn btn-dark login-hover mt-3 mb-1"
                onClick={loginMe}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "LOGIN"}
              </button>
              <a
                href="/register"
                className="btn btn-dark register-hover mb-3"
                // className="btn register-hover"
              >
                REGISTER
              </a>
            </div>
            <a href="/forgotpassword" className="forgot-password">
              Forgot your password?
            </a>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default LoginBox;
