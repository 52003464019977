import React, { useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";

const DropdownMenu = ({ title, items, showProfileIcon = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    // Handle item clicks here
    // You can navigate to the desired pages or perform other actions
    switch (item) {
      case "My Profile":
        // Navigate to the My Addresses page
         window.location.href = "/UserProfile";
        break;
      case "My Addresses":
        // Navigate to the My Addresses page
         window.location.href = "/UserProfile";
        break;
      case "All Orders":
        // Navigate to the All Orders page
         window.location.href = "/Orders";
        break;
      case "Recent Orders":
        // Navigate to the Recent Orders page
         window.location.href = "/Orders";
        break;
      case "Return Policy":
        // Navigate to the Return Policy page
         window.location.href = "/ReturnRefund";
        break;
      case "Privacy Policy":
        // Navigate to the Privacy Policy page
         window.location.href = "/PrivacyPolicy";
        break;
      case "Shipping Info":
        // Navigate to the Shipping Info page
         window.location.href = "/ShipmentPolicy";
        break;
        case "Call":
        // Navigate to the Resolve in Chat page
         window.location.href = "/";
        break;
      case "Resolve in Chat":
        // Navigate to the Resolve in Chat page
         window.location.href = "/";
        break;
      default:
        break;
    }

    setIsOpen(false);
  };

  return (
    <>
    
    <div className=" ">
      <div
        className="flex justify-between w-full items-center cursor-pointer bg-white py-2 px-4 "
        onClick={toggleDropdown}
      >
        <div className="flex justify-between items-center w-full ">
          <div className=" flex flex-col ">
            <span className="font-semibold text-black mr-4 ">{title}</span>
            {showProfileIcon && (
              // Add the image or icon here, but only if showProfileIcon is true
              <img
                src="dress1.jpg"
                alt="Profile Icon"
                className="w-6 h-6 mr-2"
              />
            )}
          </div>
          <div className=" flex flex-col ">
            <span className=" mr-3 ">{isOpen ? "-" : "+"}</span>
          </div>
        </div>
      </div>


      {/* // IF TEXT IS SHOWN SIMPLY // */}
      {isOpen && (
        <ul className="bg-white py-2 px-5 !my-0 ">
          {items.map((item, index) => (
            <li key={index} className="text-gray-600 mb-2 cursor-pointer hover:text-black "
            onClick={() => handleItemClick(item)}>
              {item}
            </li>
          ))}
        </ul>  
      )}


      {/* // IF SOMETHING(img) IS TO BE SHOWN INFRONT OF TEXT // */}
      {/* {isOpen && (
        <ul className="bg-white py-2 px-5 !my-0 ">
          {title === "My Account" && (
            <li className="text-gray-600 pb-2 ">
              <div className=" flex items-center cursor-pointer ">
              <div className="flex items-center mr-3 ">
              My Profile
              </div>
              <div className="">
                <img
                  src="dress1.jpg"
                  alt="Profile Icon"
                  className="w-6 h-6 mr-2"
                />
                </div>
                </div>
            </li>
          )}
          {title === "Customer Service" && (
            <li className="text-gray-600 pb-2 ">
              <div className=" flex items-center cursor-pointer ">
              <div className="flex items-center mr-2.5 ">
              Call
              </div>
              <div className="">
                <BsFillTelephoneFill/>
                </div>
                </div>
            </li>
          )}
          {items.map((item, index) => (
            <li key={index} className="text-gray-600 mb-2 cursor-pointer "
            onClick={() => handleItemClick(item)}>
              {item}
            </li>
          ))}
        </ul>
      )} */}

    </div>

    </>

  );
};

export default DropdownMenu;


// import React, { useState } from 'react';

// const DropdownMenu = () => {
//   const [menuItems, setMenuItems] = useState([
//     {
//       title: 'My Account',
//       items: ['Profile', 'Item 1.2', 'Item 1.3'],
//     },
//     {
//       title: 'Title 2',
//       items: ['Item 2.1', 'Item 2.2', 'Item 2.3'],
//     },
//     {
//       title: 'Title 3',
//       items: ['Item 3.1', 'Item 3.2', 'Item 3.3'],
//     },
//   ]);

//   const [selectedItem, setSelectedItem] = useState(null);

//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//     // You can add your navigation logic here
//     // For example, use React Router to navigate to a specific page
//     // import { useHistory } from 'react-router-dom';
//     // const history = useHistory();
//     // history.push('/your-page');
//   };

//   return (
//     <div className="relative inline-block text-left">
//       {menuItems.map((menu, index) => (
//         <div key={index} className="mb-2">
//           <p>
//             <a
//               className="btn w-full d-flex align-items-center justify-between"
//               role="button"
//               aria-expanded={selectedItem === menu.title}
//               onClick={() => setSelectedItem(selectedItem === menu.title ? null : menu.title)}
//             >
//               <span className={`fas fa-bars ${selectedItem === menu.title ? 'text-black' : 'text-gray-600'}`}>
//                 <span className="ps-3">{menu.title}</span>
//               </span>
//               <span className="fas fa-chevron-down"></span>
//             </a>
//           </p>
//           <div
//             className={`collapse ${selectedItem === menu.title ? 'show' : ''} border`}
//             id={menu.title}
//           >
//             <ul className="list-unstyled">
//               {menu.items.map((item, itemIndex) => (
//                 <li key={itemIndex}>
//                   <a
//                     className={`dropdown-item ${selectedItem === item ? 'text-black' : 'text-gray-600'}`}
//                     onClick={() => handleItemClick(item)}
//                   >
//                     {item}
//                   </a>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DropdownMenu;

