import React from "react";

const TermsConditions = () => {
    return(
        <div>

        </div>
    )
}

export default TermsConditions;