import AddressAndPaymentCard from "../AddressAndPaymentCard";
import React  from 'react';

import { useSelector } from "react-redux";

import FinalOrderItemCard from "../FinalOrderItemCard";
import CouponCodeCard from "../CouponCodeCard";
import PaymentConfirmationCard from "../PaymentConfirmationCard";

export default function CheckoutSection({ handleBack }) {
    const rbgColor = 'rgb(76,24,76)';

    const orderItems = useSelector((state) => state.cartReducer.cartProductVariants);
    console.log("My ordersss: ", orderItems);

    return (
        <div className="fluid-container">
            <h2 className="text-center">Review Your Order</h2>
            <p className="text-center text-sm fw-bold">Please review your order before proceeding to checkout.</p>
            <div className="row">
                <div className="col-md-7">
                    <AddressAndPaymentCard handlePrevious={handleBack} />
                    <h4 style={{ color: rbgColor }} className="my-2">Items in your order</h4>
                    {orderItems.map((orderItem) => (
                        <FinalOrderItemCard key={orderItem.id} orderItem={orderItem} accentColor={rbgColor} />
                    ))}
                </div>
                <div className="col-md-5">
                    <CouponCodeCard />
                    <PaymentConfirmationCard orderItems={orderItems} />
                </div>
            </div>
        </div>
    );
}