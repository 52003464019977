import React, { createContext, useState, useContext, useReducer } from 'react';

const CartContext = createContext();

export const CartProvider = (props) => {

  const [cart, setCart] = useState([]);

  const s1 = {
    "orderid": "13245"
  };

  // const [cart, setCart] = useState([]);
  // const addItemToCart = (item) => {
  //   setCart([...cart, item]);
  // };
  // cart, addItemToCart
 
  return (
    <CartContext.Provider value={s1}>
      {props.children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};

export default CartContext;
