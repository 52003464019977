import React from "react";
import "./Trend.css";

const Trend = () => {
  const TrendNow = [
    {
      id: 1,
      img: "https://urban-karma-ecomm.s3.eu-west-3.amazonaws.com/DSC_3614+edited.jpg",
      category: "Shirts & Blouses",
      tagLine: "Ella Blouse",
    },
    {
      id: 2,
      img: "https://urban-karma-ecomm.s3.eu-west-3.amazonaws.com/DSC_3634+edited.jpg",
      category: "Shirts & Blouses",
      tagLine: "Georgia Blouse",
    },
    {
      id: 3,
      img: "https://urban-karma-ecomm.s3.eu-west-3.amazonaws.com/DSC_3653+edited.jpg",
      category: "Shirts & Blouses",
      tagLine: "Sienna Blouse",
    },
    {
      id: 4,
      img: "https://urban-karma-ecomm.s3.eu-west-3.amazonaws.com/DSC_3666+edited.jpg",
      category: "Shirts & Blouses",
      tagLine: "Kaylee Blouse",
    },
    {
      id: 5,
      img: "https://urban-karma-ecomm.s3.eu-west-3.amazonaws.com/DSC_3686+edited.jpg",
      category: "Shirts & Blouses",
      tagLine: "Carolina Blouse",
    },
    {
      id: 6,
      img: "https://urban-karma-ecomm.s3.eu-west-3.amazonaws.com/DSC_3705+edited.jpg",
      category: "Jackets & Coats	",
      tagLine: "Clara Jacket",
    },
  ];
  return (
    <div style={{ padding: "30px 0" }} id="trends">
      <div className="new-collection-container">
        <div className="div-text-white">
          <h2 className="collection-text">
            <span># TREND NOW</span>
          </h2>
        </div>
        <div className="dark-line"></div>
      </div>
      <div className="trend-container">
        {TrendNow.map((item) => {
          return (
            <div className="trend-item" key={item.id}>
              <div className="trend-image">
                <img src={item.img} alt="" />
                <p className="trend-text">
                  Trending Now! Grab Yours
                </p>
              </div>
              <div className="trend-category">
                <p>{item.category}</p>
              </div>
              <div className="trend-tagline">
                <p>{item.tagLine}</p>
              </div>
              {/* <a href=""> */}
              <a>
                <button
                  className="trend-now-button"
                  // onClick={`/product/${item.tagLine}`}
                  onClick={`/product/Dummy%20Product%20Name`}
                >
                  SHOP NOW
                </button>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Trend;
