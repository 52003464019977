import React from "react";

const ShipmentPolicy = () => {
    return(
        <div>

            <div className=" p-16 ">
                <div className=" text-[35px] mt-2 mb-6 font-semibold ">Shipping Policy</div>
                <div className=" text-gray-600 ">We can ship to virtually any address in the world. Note that there are restrictions on some products, and some products cannot be shipped to international destinations.</div>
                <br/>
                <div className=" text-gray-600 ">When you place an order, we will estimate shipping and delivery dates for you based on the availability of your items and the shipping options you choose. Depending on the shipping provider you choose, shipping date estimates may appear on the shipping quotes page.</div>
                <br/>
                <div className=" text-gray-600 ">Please also note that the shipping rates for many items we sell are weight-based. The weight of any such item can be found on its detail page. To reflect the policies of the shipping companies we use, all weights will be rounded up to the next full pound.</div>
            </div>

        </div>
    )
}

export default ShipmentPolicy;
