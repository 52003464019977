import { FaCheck } from "react-icons/fa";
import React  from 'react';

import "./CouponCodeCard.css";

const rgbColor = 'rgb(76,24,76)';

export default function CouponCodeCard() {
    return(
        <div className="bg-light border-1 p-2 text-sm">
            <span className="fw-bold" style={{ color: 'rgb(76,24,76)' }}>Coupon Code </span>
            <div className="text-lg text-success">
                <span className="fw-bold text-sm">FIRST100 Coupon Code Applied!</span>
                <div className="justify-content-center text-center">
                    <button className="btn custom-color btn-outline-dark my-2 rounded-pill justify-content-center">Remove</button>
                </div>
            </div>
        </div>
    );
}