import { useDispatch, useSelector } from "react-redux";

import { setAddress, addAddress } from "./addressReducer";

import * as client from "../../Services/client";
import React  from 'react';

export default function AddressForm({ handleClick }) {
    const dispatch = useDispatch();

    const address = useSelector((state) => state.addressReducer.address);

    const handleSave = async (event) => {
        event.preventDefault();

        try {
            const response = await client.addAddress(address);
            dispatch(addAddress(response));
            handleClick();
        } catch (error) {
            console.error("Error adding address:", error);
        }
    };

    return (
        <form onSubmit={(e) => handleSave(e)} className="form-group">
            <p className='text-sm text-danger'>Fields marked with * need to be filled before proceeding</p>
            <div className="row">
                <div className="col-md-6">
                    <input required type="text" 
                        className="form-control rounded-pill" 
                        id="first-name" 
                        placeholder="First Name*" 
                        onChange={(e) => dispatch(setAddress({ ...address, firstname: e.target.value }))}/>
                </div>

                <div className="col-md-6">
                    <input 
                        required 
                        type="text" 
                        className="form-control rounded-pill" 
                        id="last-name" 
                        placeholder="Last Name*" 
                        onChange={(e) => dispatch(setAddress({ ...address, lastname: e.target.value }))}/>
                </div>
            </div>
            <div className="row">
                <div className="rounded-pill mt-2">
                    <input 
                        required 
                        type="text" 
                        className="form-control rounded-pill" 
                        id="address" 
                        placeholder="Street Address*" 
                        onChange={(e) => dispatch(setAddress({ ...address, addressLine1: e.target.value }))}/>
                </div>
            </div>
            <div className="row">
                <div className="rounded-pill mt-2">
                    <input 
                        type="text" 
                        className="form-control rounded-pill" 
                        id="address2" 
                        placeholder="Apartment/Suite Number" 
                        onChange={(e) => dispatch(setAddress({ ...address, addressLine2: e.target.value }))}/>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-6">
                    <input 
                        required 
                        type="text" 
                        className="form-control rounded-pill" 
                        id="city" 
                        placeholder="City*" 
                        onChange={(e) => dispatch(setAddress({ ...address, city: e.target.value }))}/>
                </div>
                <div className="col-md-6">
                    <input 
                        required 
                        type="text" 
                        className="form-control rounded-pill" 
                        id="state" 
                        placeholder="State*" 
                        onChange={(e) => dispatch(setAddress({ ...address, state: e.target.value }))}/>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-6">
                    <input 
                        required 
                        type="text" 
                        className="form-control rounded-pill" 
                        id="zip" 
                        placeholder="Zip Code*" 
                        onChange={(e) => dispatch(setAddress({ ...address, postcode: e.target.value }))}/>
                </div>
                <div className="col-md-6">
                    <input 
                        required 
                        type="text" 
                        className="form-control rounded-pill" 
                        id="country" 
                        placeholder="Country*" 
                        onChange={(e) => dispatch(setAddress({ ...address, country: e.target.value }))}/>
                </div>

            </div>
            <div className="row mt-2">
                <div>
                <input 
                    type="text" 
                    className="form-control rounded-pill" 
                    id="landmark" 
                    placeholder="Landmark (optional)" 
                    onChange={(e) => dispatch(setAddress({ ...address, landmark: e.target.value }))}/>
                </div>
            </div>
            <div className="row mt-2">
                <div>
                <input 
                    required 
                    type="text" 
                    className="form-control rounded-pill" 
                    id="phone" 
                    placeholder="Phone Number (with country code)*" 
                    onChange={(e) => dispatch(setAddress({ ...address, phone: e.target.value }))}/>
                </div>
            </div>
            <div className="row mt-2">
                <div className="justify-content-center d-flex justify-text-center">
                    <button 
                        className="btn btn-dark rounded-pill" 
                        type="submit"
                       >
                        Submit Address
                    </button>
                </div>
            </div>
        </form>
    );
}