import React, { useState, useRef, useEffect } from "react";
import Header from "../../Components/Header/Header";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import "./ProductDetailPage.css";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillFire } from "react-icons/ai";
import Slider from "react-slick";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as client from "../../Services/client";

const ProductDetailPage = (props) => {
  const navigate = useNavigate();
  // Use params
  const { name } = useParams();

  const [data1, setData1] = useState({});

  useEffect(() => {
    function product1() {
      // make api call
      axios
        .get(`https://ecom.urbankarmagb.co.uk/product/${name}`)
        //  k response
        .then((k) => {
          setData1(k.data.product);
          // const size = data1.product.variants.size.map ;
          const size = k.data.product.variants.map((variant) => variant.size);
          console.log(k);
          const quantity = k.data.product.variants.map(
            (variant) => variant.quantity
          );
          // console.log(quantity);
          const variants = k.data.product.variants;
          const maxQuantity = variants.reduce(
            (max, variant) => (variant.quantity > max ? variant.quantity : max),
            0
          );
          setMaxQuantity(maxQuantity);
          // console.log(k.data.product)
          // setData1(k["product"]);
        })
        .catch((l) => {
          console.log("Error Found");
        });
    }
    product1();
  }, []);

  const handleAddToWishlist = () => {
    axios
      .post(
        `https://ecom.urbankarmagb.co.uk/users/wishlist/add`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        },
        {
          productId: data1.slug,
        }
      )
      .then((k) => {
        console.log(k);
      })
      .catch((l) => {
        console.log(l);
      });
  };

  const handleAddToCart = () => {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null;

    const selectedVariant = data1.variants.find(
      (variant) => variant.size === selectedSize
    );
    if (!selectedVariant) {
      console.log("No variant selected");
      toast.error("Please Select Variant Size");
    } else {
      if (token) {
        // User is logged in, add directly to cart
        client.addToCart(selectedVariant.id, quantity);
        toast.success("Product added to cart!");
        // navigate("./cart");
      } else {
        // User is not logged in, store in localStorage
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        console.log("Inside of else", selectedVariant);
        cartItems.push({ variantId: selectedVariant.id, quantity: quantity });
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
      }
    }
  };

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const sliderRef = useRef();
  var settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: windowSize.current[0] > 600 ? 2 : 1,
    slidesToScroll: 2,
  };

  const [selectedSize, setSelectedSize] = useState(""); // Initial size selection
  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };

  const [quantity, setQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(1);
  const handleQuantityChange = (amount) => {
    const newQuantity = quantity + amount;
    const clampedQuantity = Math.max(1, Math.min(newQuantity, maxQuantity));

    setQuantity(clampedQuantity);
  };

  const [selectedColor, setSelectedColor] = useState("blue"); // Initial color selection
  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const colorOptions = {
    blue: "./dress1.jpg",
    red: "./dress2.jpg",
    // green: 'green-product-image.jpg',
  };

  const selectedImage = colorOptions[selectedColor];

  const [select, setSelect] = React.useState(0);

  const data = [
    {
      id: 0,
      question: <div className=" text-black text-[21px] "></div>,
      answer: data1.description,
    },
    {
      id: 1,
      question: <div className=" text-black text-[21px] ">1 Review</div>,
      answer: "sacvdscx" + "Posted by cxcvdfvxb on 4th Jul 2023",
    },
    {
      id: 2,
      question: <div className=" text-black text-[20px] ">Returns Policy</div>,
      answer:
        "You may return most new, unopened items within 30 days of delivery for a full refund. We'll also pay the return shipping costs if the return is a result of our error (you received an incorrect or defective item, etc.)." +
        "You should expect to receive your refund within four weeks of giving your package to the return shipper, however, in many cases you will receive a refund more quickly. This time period includes the transit time for us to receive your return from the shipper (5 to 10 business days), the time it takes us to process your return once we receive it (3 to 5 business days), and the time it takes your bank to process our refund request (5 to 10 business days)." +
        "If you need to return an item, simply login to your account, view the order using the Complete Orders link under the My Account menu and click the Return Item(s) button. We'll notify you via e-mail of your refund once we've received and processed the returned item.",
      question: <div className=" text-black text-[20px] ">Shipping</div>,
      answer:
        "We can ship to virtually any address in the world. Note that there are restrictions on some products, and some products cannot be shipped to international destinations." +
        "When you place an order, we will estimate shipping and delivery dates for you based on the availability of your items and the shipping options you choose. Depending on the shipping provider you choose, shipping date estimates may appear on the shipping quotes page." +
        "Please also note that the shipping rates for many items we sell are weight-based. The weight of any such item can be found on its detail page. To reflect the policies of the shipping companies we use, all weights will be rounded up to the next full pound.",
    },
    {
      id: 3,
      question: (
        <div className=" text-black text-[20px] ">
          Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
        </div>
      ),
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at venenatis eros. Nulla efficitur, orci ut cursus consectetur, nisi elit convallis odio, non hendrerit arcu tortor aliquet eros. Nullam imperdiet diam ut neque ullamcorper semper. Mauris consequat ex sed elit venenatis, eu varius nulla posuere. Nullam gravida mattis velit, id commodo nunc rhoncus ut. Phasellus congue felis tortor, nec eleifend lacus tincidunt vitae. Nullam dapibus tempus tempor. Maecenas massa neque, tempus in efficitur at, scelerisque sed tortor. Morbi vulputate ipsum odio, non posuere sem suscipit accumsan. Cras fermentum nunc quis tempor iaculis. Praesent dictum augue sit amet neque faucibus, ac varius enim convallis. Curabitur volutpat ligula sit amet nibh vehicula egestas. Etiam eget dolor ipsum. Phasellus varius, metus sit amet aliquet tempor, erat ante rhoncus magna, quis vehicula tortor nunc at ipsum. Duis vitae erat vitae turpis cursus pretium. Quisque pulvinar sapien at mi efficitur, sed faucibus nulla accumsan." +
        "Phasellus finibus nulla vitae malesuada efficitur. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus sed pharetra risus. Quisque ante sem, faucibus id velit eget, bibendum sodales purus. Donec congue ipsum leo, aliquet aliquam lectus suscipit et. Pellentesque viverra, enim et pulvinar aliquet, dolor magna gravida lectus, sit amet pellentesque turpis elit id enim. Nulla a lectus viverra, ultricies urna ac, cursus turpis. Sed et purus vitae nunc molestie molestie sit amet eget nisl. Ut non nunc erat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus" +
        "",
    },
  ];

  return (
    <div>
      <Header />
      <div className=" !bg-white ">
        <div>
          <div className=" flex flex-col md:flex-row justify-center ">
            <div className=" flex md:flex-row flex-col-reverse ">
              <div className=" flex md:flex-col justify-center items-center ">
                <div className=" bg-white md:my-3 md:m-0 m-1 ">
                  <img
                    className=" md:!h-24 h-20 "
                    src={data1["galleryImage[0]"]}
                    // src={data1.galleryImage[0]}
                  />
                </div>
                <div className=" bg-white md:my-3 md:m-0 m-1 ">
                  <img
                    className=" md:!h-24 h-20 "
                    src={data1["galleryImage[1]"]}
                    // src={data1.galleryImage[1]}
                  />
                </div>
                {/* <div className=" bg-white md:my-3 md:m-0 m-1 ">
                      <img className=" md:!h-24 h-20 " src="./dress4.jpg" />
                    </div>
                    <div className=" bg-white md:my-3 md:m-0 m-1 ">
                      <img className=" md:!h-24 h-20 " src="./dress2.jpg" />
                    </div> */}
              </div>
              <div class=" left-column mt-6 relative md:ml-3 ">
                <img
                  className="flex justify-center items-center md:my-4 md:w-[550px] w-[300px] md:p-0 p-6 md:h-[720px]"
                  src={data1["coverImage"]}
                  // alt={`Product in ${selectedColor}`}
                />
                <div className=" flex flex-col absolute top-[40px] md:left-[20px] left-[30px] ">
                  <div className="">
                    <p className="blacktext border-2 border-black py-0.5 px-2 bg-white !mb-2 text-[12px] font-semibold shadow-md shadow-black-900  ">
                      NEW
                    </p>
                  </div>
                  <div className="">
                    <p className="redtext border-2 border-red-600 py-0.5 px-2 bg-white text-red-500 text-[12px] font-semibold shadow-md shadow-red-400  ">
                      SALE
                    </p>
                  </div>
                </div>
                {/* <img data-image="black" src="dress.jpeg" alt="" /> */}
              </div>
            </div>

            <div class="right-column">
              {/* <!-- Product Description --> */}
              <div class="product-description">
                <span className="text-[17px] font-bold hover:text-black duration-1000 ">
                  {data1["name"]}
                </span>
                <h1 className=" text-[22px] font-bold mt-3 ">
                  {data1["description"]}
                </h1>
                <div className=" flex my-3 items-center ">
                  <div className=" flex mr-2 ">
                    <div className=" ">
                      {" "}
                      <AiFillStar />{" "}
                    </div>
                    <div className=" ">
                      {" "}
                      <AiFillStar />{" "}
                    </div>
                    <div className="  ">
                      {" "}
                      <AiFillStar />{" "}
                    </div>
                    <div className=" ">
                      {" "}
                      <AiOutlineStar />{" "}
                    </div>
                    <div className=" ">
                      {" "}
                      <AiOutlineStar />{" "}
                    </div>
                  </div>
                  <div className=" mx-2  ">
                    <a
                      href="#review-section"
                      className="hover:underline underline-offset-4 !text-black text-[14px] "
                    >
                      1 review{" "}
                    </a>
                  </div>
                  <div className=" mx-2 ">
                    <a
                      href="#"
                      className="underline underline-offset-4 !text-black text-[14px] font-medium "
                    >
                      Write a Review
                    </a>
                  </div>
                  <div className=" flex items-center ">
                    <div className=" mx-1 bg-red ">
                      <AiFillFire />{" "}
                    </div>
                    <p className="!text-red text-[15px] !mb-0 ">
                      26 sold in last 36 hour
                    </p>
                  </div>
                </div>
              </div>

              <div class="product-configuration">
                <div className="flex flex-col">
                  <div className="">
                    <p className=" text-[14px] underline decoration-dotted underline-offset-8 ">
                      Limited-Time Offer, End Ins:{" "}
                    </p>
                  </div>
                  <div className=" flex ">
                    <div className=" md:mr-6 text-[20px] ">
                      <p className=" line-through text-stone-400 ">
                        £{data1["salePrice"]}
                      </p>
                    </div>
                    <div className="">
                      <p className=" text-[red] text-[20px] font-semibold">
                        £{data1["discountedPrice"]}
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <p className=" text-stone-400 italic md:!mb-2 ">
                      {" "}
                      (You save $80.00){" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className=" flex md:mt-4 !font-Poppins ">
                <div className=" flex flex-col md:mr-4 mr-10 text-[15px] font-semibold ">
                  <p className=" !mb-2 ">Availability:</p>
                  {/* <p className=" !mb-2 ">Weight:</p> */}
                  <p className=" !mb-2 ">Gift wrapping:</p>
                  <p className=" !mb-2">Shipping:</p>
                  <p className=" ">Size:</p>
                </div>
                <div className=" flex flex-col text-[15px] ">
                  <p className="!mb-2 ">
                    {data1.isInStock ? "Available" : "Not Available"}
                  </p>
                  {/* <p className=" !mb-2 ">2 LBS</p> */}
                  <p className=" !mb-2 ">Options available</p>
                  <p className=" !mb-2 ">Free Shipping</p>
                  <p className="">
                    {data1.variants && (
                      <div>
                        {data1.variants.length > 0 && (
                          <>
                            {data1.variants[0].quantity > 0 && (
                              <label>
                                <input
                                  className="mx-0.5"
                                  type="radio"
                                  name="size"
                                  value={data1.variants[0].size}
                                  checked={
                                    selectedSize === data1.variants[0].size
                                  }
                                  onChange={() =>
                                    handleSizeChange(data1.variants[0].size)
                                  }
                                />
                                {data1.variants[0].size}
                                {/* {`${data1.variants[0].size} - Quantity: ${data1.variants[0].quantity}`} */}
                              </label>
                            )}

                            {data1.variants.length > 1 &&
                              data1.variants[1].quantity > 0 && (
                                <>
                                  <label className="mx-1">
                                    <input
                                      className="mx-0.5"
                                      type="radio"
                                      name="size"
                                      value={data1.variants[1].size}
                                      checked={
                                        selectedSize === data1.variants[1].size
                                      }
                                      onChange={() =>
                                        handleSizeChange(data1.variants[1].size)
                                      }
                                    />
                                    {data1.variants[1].size}
                                    {/* {`${data1.variants[1].size} - Quantity: ${data1.variants[1].quantity}`} */}
                                  </label>
                                </>
                              )}

                            {data1.variants.length > 2 &&
                              data1.variants[2].quantity > 0 && (
                                <>
                                  <label className="mx-1">
                                    <input
                                      className="mx-0.5"
                                      type="radio"
                                      name="size"
                                      value={data1.variants[2].size}
                                      checked={
                                        selectedSize === data1.variants[2].size
                                      }
                                      onChange={() =>
                                        handleSizeChange(data1.variants[2].size)
                                      }
                                    />
                                    {data1.variants[2].size}
                                    {/* {`${data1.variants[1].size} - Quantity: ${data1.variants[1].quantity}`} */}
                                  </label>
                                </>
                              )}
                          </>
                        )}
                      </div>
                    )}
                  </p>
                </div>
              </div>

              {/* <!-- Product Color --> */}
              {/* <div class="product-color md:mt-3 ">
                <span className=" mb-2 font-semibold text-[15px] ">
                  Color :{" "}
                </span>

                <div class="color-choose ">
                  <div>
                    <input
                      onClick={() => handleColorChange("red")}
                      className={selectedColor === "red" ? "selected" : ""}
                      data-image="red"
                      type="radio"
                      id="red"
                      name="color"
                      value="red"
                      checked
                    />
                    <label for="red">
                      <span></span>
                    </label>
                  </div>
                  <div>
                    <input
                      onClick={() => handleColorChange("blue")}
                      className={selectedColor === "blue" ? "selected" : ""}
                      data-image="blue"
                      type="radio"
                      id="blue"
                      name="color"
                      value="blue"
                    />
                    <label for="blue">
                      <span></span>
                    </label>
                  </div>
                  <div>
                    <input
                      data-image="black"
                      type="radio"
                      id="black"
                      name="color"
                      value="black"
                    />
                    <label for="black">
                      <span></span>
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="">
                <div className="">
                  <p className=" font-semibold !mb-1.5 text-[15px] ">
                    Quantity:
                  </p>
                </div>
                <div class="container1 !md:mt-1 md:!mb-6 ">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <div class="qty-container ">
                        <div className=" flex ">
                          <button
                            type="button"
                            onClick={() => handleQuantityChange(-1)}
                            class="qty-btn-minus btn-rounded mr-2"
                          >
                            <i class="fa fa-chevron-down"></i>
                          </button>
                          <div className=" border-1 border-gray-300 md:px-5 px-4 flex items-center ">
                            {quantity}
                          </div>
                          <button
                            type="button"
                            onClick={() => handleQuantityChange(1)}
                            class="qty-btn-plus btn-rounded ml-2"
                          >
                            <i class="fa fa-chevron-up"></i>
                          </button>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Product Pricing --> */}
              <div class=" product-price ">
                <a
                  // href="/Cart"
                  href="#"
                  class="cart-btn md:mr-8 border-2 border-black !hover:text-black font-semibold !font-Poppins "
                  onClick={handleAddToCart}
                >
                  ADD TO CART
                </a>
                <a
                  // href="/wishlist"
                  class="cart-btn1 border-2 border-black !hover:text-white text-black focus:outline-none focus:ring font-semibold "
                  onClick={handleAddToWishlist}
                >
                  + WISH LIST
                </a>
              </div>

              <div className=" flex md:mt-8 mt-6 text-gray-500 font-light ">
                <div className=" mr-2 ">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </div>
                <div className="">
                  <p className=" md:text-[16px] text-[13px] ">
                    {" "}
                    11 customers are viewing this product{" "}
                  </p>
                </div>
              </div>

              <div className=" mt-3 ">
                <div className=" flex md:h-12 h-8 ">
                  <img className=" md:!mr-4 mr-2 " src="/security-1.png" />
                  <img className=" md:!mr-4 mr-2 " src="/security-2.png" />
                  <img className=" md:!mr-4 mr-2 " src="/security-3.png" />
                  <img className=" md:!mr-4 mr-2 " src="/security-4.png" />
                </div>
              </div>

              {/* <div className=" border-2 border-black rounded-xl mt-4 md:w-[70%] flex justify-center items-center cursor-pointer bg-sky-600 text-white hover:bg-blue-200,text-black ">
            <h3 className=" text-[23px] mt-2 hover:text-black py-1 px-3 ">
              BUY NOW
            </h3>
          </div> */}
            </div>
          </div>

          <div className=" md:mt-20 mb-16 ">
            <div className=" flex !flow-col justify-center ">
              <div
                className={` cursor-pointer transition duration-500 
            ${
              select == 0
                ? " bg-white border-t-2 border-r-0 border-b-0 border-l-0 border-black "
                : " bg-[#f7f8f9] border-1 border-gray-300 "
            } `}
                onClick={() => {
                  setSelect(0);
                }}
              >
                <h5 className=" font-bold text-[17px] md:mx-20 my-3 ">
                  DETAILS
                </h5>
              </div>

              <div
                id="review-section"
                className={` cursor-pointer transition duration-500 
            ${
              select == 1
                ? " bg-white border-t-2 border-r-0 border-b-0 border-l-0 border-black "
                : " bg-[#f7f8f9] border-1 border-gray-300 "
            } `}
                onClick={() => {
                  setSelect(1);
                }}
              >
                {/* <h5 className=" font-bold text-[17px] md:mx-20 my-3 ">
                  CUSTOMER REVIEWS
                </h5> */}
              </div>

              <div
                className={`cursor-pointer transition duration-500 
            ${
              select == 2
                ? " bg-white border-t-2 border-r-0 border-b-0 border-l-0 border-black "
                : " bg-[#f7f8f9] border-1 border-gray-300 "
            } `}
                onClick={() => {
                  setSelect(2);
                }}
              >
                <h5 className=" font-bold text-[17px] md:mx-20 my-3 ">
                  SHIPPING & RETURNS
                </h5>
              </div>

              <div
                className={` cursor-pointer transition duration-500 
            ${
              select == 3
                ? " bg-white border-t-2 border-r-0 border-b-0 border-l-0 border-black "
                : " bg-[#f7f8f9] border-1 border-gray-300 "
            } `}
                onClick={() => {
                  setSelect(3);
                }}
              >
                {/* <h5 className=" font-bold text-[17px] md:mx-20 my-3 ">
                  CUSTOM TAB
                </h5> */}
              </div>
            </div>

            <div className="flex flex-col align-items justify-center px-28 py-10 ">
              <h3 className="text-[#3D47C4] font-bold py-3 ">
                {data[select]["question"]}
              </h3>
              <p className="text-base">{data[select]["answer"]}</p>
            </div>
          </div>

          {/* <hr class="w-10/12 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr> */}

          <div className=" md:mt-14 md:px-10 px-3 mb-16 ">
            {/* <h5 className=" font-bold md:mx-20 ">CUSTOMERS ALSO VIEWED</h5> */}

            {/* <div className="hidden md:flex justify-between items-center px-10 py-4 flex-col my-2 flex items-center justify-evenly ">
              <div className="flex my-2 ">
                <button
                  className="flex items-center border-2 border-black text-black px-3 py-1 rounded mx-1"
                  onClick={() => {
                    sliderRef.current.slickPrev();
                  }}
                >
                  <FaArrowLeft className="mr-2" />
                </button>
                <button
                  className="flex items-center border-2 border-black text-black px-3 py-1 rounded mx-3"
                  onClick={() => {
                    sliderRef.current.slickNext();
                  }}
                >
                  <FaArrowRight className="mx-2" />
                </button>
              </div>
            </div> */}

            <div className="mt-10 md:m-20 md:mt-0 mb-20 ">
              {/* <Slider
                ref={sliderRef}
                {...settings2}
                className="mb-4"
                dots={true}
              >
                <div
                  className=" md:p-4 md:w-auto rounded-xl p-1 "
                  data-aos="fade-down"
                  data-aos-delay="400"
                >
                  <div className=" !flex !justify-center ">
                    <div class=" mt-6 relative md:ml-3 ">
                      <img
                        className=" md:my-4 md:h-72 h-40 "
                        src={selectedImage}
                        alt={`Product in ${selectedColor}`}
                      />
                      <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                        <div className="">
                          <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                            NEW
                          </p>
                        </div>
                        <div className="">
                          <p className="greytext border-2 py-0.5 md:px-2 px-1 bg-gray-700 text-white md:text-[12px] text-[10px] font-semibold shadow-md shadow-red-400  ">
                            BUNDLE
                          </p>
                        </div>
                      </div>
                      <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                        <i class="fa fa-heart-o" aria-hidden="true"></i>
                      </div>
                      {/* <img data-image="black" src="dress.jpeg" alt="" /> */}
            </div>

            {/* <div className=" flex flex-col mr-3 ">
                  <div className=" ">
                    <p className="blacktext flex justify-center border-2 border-black py-0.5 px-1 bg-white !mb-2 text-[12px] font-semibold shadow-md shadow-black-900  ">
                      NEW
                    </p>
                  </div>
                  <div className="">
                    <p className="greytext border-2 border-White-600 py-1 px-2 bg-gray-700 text-white text-[12px] font-semibold shadow-md shadow-white-400 ">
                      BUNDLE
                    </p>
                  </div>
                </div> */}
            {/* <div className="">
                  <img className="h-28 !mb-2" src="dress1.jpg" />
                </div> 
                <div className="">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
                </div>  */}
            {/* </div>
                  <div className=" flex flex-col items-center ">
                    <p className=" font-semibold !mb-2 ">COCO LEE</p>
                    <p className="  text-[14px] !mb-2 text-center ">
                      coco lee, coins are kumis brown leather bag
                    </p>
                    <p className="font-semibold ">$199.00</p>
                  </div>
                </div>

                <div
                  className=" md:p-4 md:w-auto rounded-xl p-1 "
                  data-aos="fade-down"
                  data-aos-delay="400"
                >
                  <div className=" !flex !justify-center ">
                    <div class=" mt-6 relative md:ml-3 ">
                      <img
                        className=" md:my-4 md:h-72 h-40 "
                        src={selectedImage}
                        alt={`Product in ${selectedColor}`}
                      />
                      <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                        <div className="">
                          <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                            NEW
                          </p>
                        </div>
                        <div className=" ">
                          <p className="redtext border-2 border-red-600 py-0.5 md:px-2 px-1 bg-white text-red-500 md:text-[12px] text-[12px] font-semibold shadow-md shadow-red-400  ">
                            SALE
                          </p>
                        </div>
                      </div>
                      <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                        <i class="fa fa-heart-o" aria-hidden="true"></i>
                      </div> */}
            {/* <img data-image="black" src="dress.jpeg" alt="" /> */}
            {/* </div>
                  </div>
                  <div className=" flex flex-col items-center ">
                    <p className=" font-semibold !mb-2 ">COCO LEE</p>
                    <p className="  text-[14px] !mb-2 text-center ">
                      coco lee, coins are kumis brown leather bag
                    </p>
                    <p className="font-semibold ">$199.00</p>
                  </div>
                </div> */}

            {/* <div
                  className=" md:p-4 md:w-auto rounded-xl p-1 "
                  data-aos="fade-down"
                  data-aos-delay="400"
                >
                  <div className=" !flex !justify-center ">
                    <div class=" mt-6 relative md:ml-3 ">
                      <img
                        className=" md:my-4 md:h-72 h-40 "
                        src={selectedImage}
                        alt={`Product in ${selectedColor}`}
                      />
                      <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                        <div className="">
                          <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                            NEW
                          </p>
                        </div>
                        <div className=" ">
                          <p className="redtext border-2 border-red-600 py-0.5 md:px-2 px-1 bg-white text-red-500 md:text-[12px] text-[12px] font-semibold shadow-md shadow-red-400  ">
                            SALE
                          </p>
                        </div>
                      </div>
                      <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                        <i class="fa fa-heart-o" aria-hidden="true"></i>
                      </div>
                      {/* <img data-image="black" src="dress.jpeg" alt="" /> */}
            {/* </div> 
                  </div>
                  <div className=" flex flex-col items-center ">
                    <p className=" font-semibold !mb-2 ">COCO LEE</p>
                    <p className="  text-[14px] !mb-2 text-center ">
                      coco lee, coins are kumis brown leather bag
                    </p>
                    <p className="font-semibold ">$199.00</p>
                  </div>
                </div> */}

            {/* <div
                  className=" md:p-4 md:w-auto rounded-xl p-1 "
                  data-aos="fade-down"
                  data-aos-delay="400"
                >
                  <div className=" !flex !justify-center ">
                    <div class=" mt-6 relative md:ml-3 ">
                      <img
                        className=" md:my-4 md:h-72 h-40 "
                        src={selectedImage}
                        alt={`Product in ${selectedColor}`}
                      />
                      <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                        <div className="">
                          <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                            NEW
                          </p>
                        </div>
                        <div className=" ">
                          <p className="redtext border-2 border-red-600 py-0.5 md:px-2 px-1 bg-white text-red-500 md:text-[12px] text-[12px] font-semibold shadow-md shadow-red-400  ">
                            SALE
                          </p>
                        </div>
                      </div>
                      <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                        <i class="fa fa-heart-o" aria-hidden="true"></i>
                      </div> */}
            {/* <img data-image="black" src="dress.jpeg" alt="" /> */}
            {/* </div> */}

            {/* <div className=" flex flex-col mr-3 ">
                  <div className=" ">
                    <p className="blacktext flex justify-center border-2 border-black py-0.5 px-1 bg-white !mb-2 text-[12px] font-semibold shadow-md shadow-black-900  ">
                      NEW
                    </p>
                  </div>
                  <div className="">
                    <p className="greytext border-2 border-White-600 py-1 px-2 bg-gray-700 text-white text-[12px] font-semibold shadow-md shadow-white-400 ">
                      BUNDLE
                    </p>
                  </div>
                </div> */}
            {/* <div className="">
                  <img className="h-28 !mb-2" src="dress1.jpg" />
                </div> 
                <div className="">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
                </div>  */}
            {/* </div>
                  <div className=" flex flex-col items-center ">
                    <p className=" font-semibold !mb-2 ">COCO LEE</p>
                    <p className="  text-[14px] !mb-2 text-center ">
                      coco lee, coins are kumis brown leather bag
                    </p>
                    <p className="font-semibold ">$199.00</p>
                  </div> */}
            {/* </div>

                <div
                  className=" md:p-4 md:w-auto rounded-xl p-1 "
                  data-aos="fade-down"
                  data-aos-delay="400"
                >
                  <div className=" !flex !justify-center ">
                    <div class=" mt-6 relative md:ml-3 ">
                      <img
                        className=" md:my-4 md:h-72 h-40 "
                        src={selectedImage}
                        alt={`Product in ${selectedColor}`}
                      />
                      <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                        <div className="">
                          <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                            NEW
                          </p>
                        </div>
                        <div className="">
                          <p className="greytext border-2 py-0.5 md:px-2 px-1 bg-gray-700 text-white md:text-[12px] text-[10px] font-semibold shadow-md shadow-red-400  ">
                            BUNDLE
                          </p>
                        </div>
                      </div>
                      <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                        <i class="fa fa-heart-o" aria-hidden="true"></i>
                      </div> */}
            {/* <img data-image="black" src="dress.jpeg" alt="" /> */}
            {/* </div> */}

            {/* <div className=" flex flex-col mr-3 ">
                  <div className=" ">
                    <p className="blacktext flex justify-center border-2 border-black py-0.5 px-1 bg-white !mb-2 text-[12px] font-semibold shadow-md shadow-black-900  ">
                      NEW
                    </p>
                  </div>
                  <div className="">
                    <p className="greytext border-2 border-White-600 py-1 px-2 bg-gray-700 text-white text-[12px] font-semibold shadow-md shadow-white-400 ">
                      BUNDLE
                    </p>
                  </div>
                </div> */}
            {/* <div className="">
                  <img className="h-28 !mb-2" src="dress1.jpg" />
                </div> 
                <div className="">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
                </div>  */}
            {/* </div>
                  <div className=" flex flex-col items-center ">
                    <p className=" font-semibold !mb-2 ">COCO LEE</p>
                    <p className="  text-[14px] !mb-2 text-center ">
                      coco lee, coins are kumis brown leather bag
                    </p>
                    <p className="font-semibold ">$199.00</p>
                  </div>
                </div>

                <div
                  className=" md:p-4 md:w-auto rounded-xl p-1 "
                  data-aos="fade-down"
                  data-aos-delay="400"
                >
                  <div className=" !flex !justify-center ">
                    <div class=" mt-6 relative md:ml-3 ">
                      <img
                        className=" md:my-4 md:h-72 h-40 "
                        src={selectedImage}
                        alt={`Product in ${selectedColor}`}
                      />
                      <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                        <div className="">
                          <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                            NEW
                          </p>
                        </div>
                        <div className=" ">
                          <p className="redtext border-2 border-red-600 py-0.5 md:px-2 px-1 bg-white text-red-500 md:text-[12px] text-[12px] font-semibold shadow-md shadow-red-400  ">
                            SALE
                          </p>
                        </div>
                      </div>
                      <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                        <i class="fa fa-heart-o" aria-hidden="true"></i>
                      </div> */}
            {/* <img data-image="black" src="dress.jpeg" alt="" /> */}
            {/* </div> */}

            {/* <div className=" flex flex-col mr-3 ">
                  <div className=" ">
                    <p className="blacktext flex justify-center border-2 border-black py-0.5 px-1 bg-white !mb-2 text-[12px] font-semibold shadow-md shadow-black-900  ">
                      NEW
                    </p>
                  </div>
                  <div className="">
                    <p className="greytext border-2 border-White-600 py-1 px-2 bg-gray-700 text-white text-[12px] font-semibold shadow-md shadow-white-400 ">
                      BUNDLE
                    </p>
                  </div>
                </div> */}
            {/* <div className="">
                  <img className="h-28 !mb-2" src="dress1.jpg" />
                </div> 
                <div className="">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
                </div>  */}
            {/* </div>
                  <div className=" flex flex-col items-center ">
                    <p className=" font-semibold !mb-2 ">COCO LEE</p>
                    <p className="  text-[14px] !mb-2 text-center ">
                      coco lee, coins are kumis brown leather bag
                    </p>
                    <p className="font-semibold ">$199.00</p>
                  </div>
                </div>
              </Slider> */}
            {/* </div> */}
          </div>
        </div>
      </div>

      {/* <div className=" md:mt-20 mb-16 ">
        <div className=" flex !flow-col justify-center ">
          <div
            className={`  cursor-pointer transition duration-500 
            ${
              select == 0
                ? " bg-white border-t-2 border-r-0 border-b-0 border-l-0 border-black "
                : " bg-[#f7f8f9] border-1 border-gray-300 "
            } `}
            onClick={() => {
              setSelect(0);
            }}
          >
            <h5 className=" font-bold text-[17px] md:mx-20 my-3 ">DETAILS</h5>
          </div>

          <div
            id="review-section"
            className={` cursor-pointer transition duration-500 
            ${
              select == 1
                ? " bg-white border-t-2 border-r-0 border-b-0 border-l-0 border-black "
                : " bg-[#f7f8f9] border-1 border-gray-300 "
            } `}
            onClick={() => {
              setSelect(1);
            }}
          >
            <h5 className=" font-bold text-[17px] md:mx-20 my-3 ">
              CUSTOMER REVIEWS
            </h5>
          </div>

          <div
            className={`cursor-pointer transition duration-500 
            ${
              select == 2
                ? " bg-white border-t-2 border-r-0 border-b-0 border-l-0 border-black "
                : " bg-[#f7f8f9] border-1 border-gray-300 "
            } `}
            onClick={() => {
              setSelect(2);
            }}
          >
            <h5 className=" font-bold text-[17px] md:mx-20 my-3 ">
              SHIPPING & RETURNS
            </h5>
          </div>

          <div
            className={` cursor-pointer transition duration-500 
            ${
              select == 3
                ? " bg-white border-t-2 border-r-0 border-b-0 border-l-0 border-black "
                : " bg-[#f7f8f9] border-1 border-gray-300 "
            } `}
            onClick={() => {
              setSelect(3);
            }}
          >
            <h5 className=" font-bold text-[17px] md:mx-20 my-3 ">
              CUSTOM TAB
            </h5>
          </div>
        </div>

        <div className="flex flex-col align-items justify-center px-28 py-10 ">
          <h3 className="text-[#3D47C4] font-bold py-3 ">
            {data[select]["question"]}
          </h3>
          <p className="text-base">{data[select]["answer"]}</p>
        </div>
      </div>

      <hr class="w-10/12 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>

      <div className=" md:mt-14 md:px-10 px-3 mb-16 ">
        <h5 className=" font-bold md:mx-20 ">CUSTOMERS ALSO VIEWED</h5>

        <div className="hidden md:flex justify-between items-center px-10 py-4 flex-col my-2 flex items-center justify-evenly ">
          <div className="flex my-2 ">
            <button
              className="flex items-center border-2 border-black text-black px-3 py-1 rounded mx-1"
              onClick={() => {
                sliderRef.current.slickPrev();
              }}
            >
              <FaArrowLeft className="mr-2" />
            </button>
            <button
              className="flex items-center border-2 border-black text-black px-3 py-1 rounded mx-3"
              onClick={() => {
                sliderRef.current.slickNext();
              }}
            >
              <FaArrowRight className="mx-2" />
            </button>
          </div>
        </div>

        <div className="mt-10 md:m-20 md:mt-0 mb-20 ">
          <Slider ref={sliderRef} {...settings2} className="mb-4" dots={true}>

            <div className=" md:p-4 md:w-auto rounded-xl p-1 "
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className=" !flex !justify-center ">
                <div class=" mt-6 relative md:ml-3 ">
                  <img
                    className=" md:my-4 md:h-72 h-40 "
                    src={selectedImage}
                    alt={`Product in ${selectedColor}`}
                  />
                  <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                    <div className="">
                      <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                        NEW
                      </p>
                    </div>
                    <div className="">
                      <p className="greytext border-2 py-0.5 md:px-2 px-1 bg-gray-700 text-white md:text-[12px] text-[10px] font-semibold shadow-md shadow-red-400  ">
                        BUNDLE
                      </p>
                    </div>
                  </div>
                  <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                  </div>

                </div>

              </div>
              <div className=" flex flex-col items-center ">
                <p className=" font-semibold !mb-2 ">COCO LEE</p>
                <p className="  text-[14px] !mb-2 text-center ">
                  coco lee, coins are kumis brown leather bag
                </p>
                <p className="font-semibold ">$199.00</p>
              </div>
            </div>

            <div className=" md:p-4 md:w-auto rounded-xl p-1 "
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className=" !flex !justify-center ">
                <div class=" mt-6 relative md:ml-3 ">
                  <img
                    className=" md:my-4 md:h-72 h-40 "
                    src={selectedImage}
                    alt={`Product in ${selectedColor}`}
                  />
                  <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                    <div className="">
                      <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                        NEW
                      </p>
                    </div>
                    <div className=" ">
                      <p className="redtext border-2 border-red-600 py-0.5 md:px-2 px-1 bg-white text-red-500 md:text-[12px] text-[12px] font-semibold shadow-md shadow-red-400  ">
                        SALE
                      </p>
                    </div>
                  </div>
                  <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                  </div>
                </div>

              </div>
              <div className=" flex flex-col items-center ">
                <p className=" font-semibold !mb-2 ">COCO LEE</p>
                <p className="  text-[14px] !mb-2 text-center ">
                  coco lee, coins are kumis brown leather bag
                </p>
                <p className="font-semibold ">$199.00</p>
              </div>
            </div>

            <div className=" md:p-4 md:w-auto rounded-xl p-1 "
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className=" !flex !justify-center ">
                <div class=" mt-6 relative md:ml-3 ">
                  <img
                    className=" md:my-4 md:h-72 h-40 "
                    src={selectedImage}
                    alt={`Product in ${selectedColor}`}
                  />
                  <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                    <div className="">
                      <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                        NEW
                      </p>
                    </div>
                    <div className=" ">
                      <p className="redtext border-2 border-red-600 py-0.5 md:px-2 px-1 bg-white text-red-500 md:text-[12px] text-[12px] font-semibold shadow-md shadow-red-400  ">
                        SALE
                      </p>
                    </div>
                  </div>
                  <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                  </div>
                </div>

              </div>
              <div className=" flex flex-col items-center ">
                <p className=" font-semibold !mb-2 ">COCO LEE</p>
                <p className="  text-[14px] !mb-2 text-center ">
                  coco lee, coins are kumis brown leather bag
                </p>
                <p className="font-semibold ">$199.00</p>
              </div>
            </div>

            <div className=" md:p-4 md:w-auto rounded-xl p-1 "
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className=" !flex !justify-center ">
                <div class=" mt-6 relative md:ml-3 ">
                  <img
                    className=" md:my-4 md:h-72 h-40 "
                    src={selectedImage}
                    alt={`Product in ${selectedColor}`}
                  />
                  <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                    <div className="">
                      <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                        NEW
                      </p>
                    </div>
                    <div className=" ">
                      <p className="redtext border-2 border-red-600 py-0.5 md:px-2 px-1 bg-white text-red-500 md:text-[12px] text-[12px] font-semibold shadow-md shadow-red-400  ">
                        SALE
                      </p>
                    </div>
                  </div>
                  <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div className=" flex flex-col items-center ">
                <p className=" font-semibold !mb-2 ">COCO LEE</p>
                <p className="  text-[14px] !mb-2 text-center ">
                  coco lee, coins are kumis brown leather bag
                </p>
                <p className="font-semibold ">$199.00</p>
              </div>
            </div>

            <div className=" md:p-4 md:w-auto rounded-xl p-1 "
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className=" !flex !justify-center ">
                <div class=" mt-6 relative md:ml-3 ">
                  <img
                    className=" md:my-4 md:h-72 h-40 "
                    src={selectedImage}
                    alt={`Product in ${selectedColor}`}
                  />
                  <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                    <div className="">
                      <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                        NEW
                      </p>
                    </div>
                    <div className="">
                      <p className="greytext border-2 py-0.5 md:px-2 px-1 bg-gray-700 text-white md:text-[12px] text-[10px] font-semibold shadow-md shadow-red-400  ">
                        BUNDLE
                      </p>
                    </div>
                  </div>
                  <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                  </div>
                </div>

              </div>
              <div className=" flex flex-col items-center ">
                <p className=" font-semibold !mb-2 ">COCO LEE</p>
                <p className="  text-[14px] !mb-2 text-center ">
                  coco lee, coins are kumis brown leather bag
                </p>
                <p className="font-semibold ">$199.00</p>
              </div>
            </div>

            <div className=" md:p-4 md:w-auto rounded-xl p-1 "
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className=" !flex !justify-center ">
                <div class=" mt-6 relative md:ml-3 ">
                  <img
                    className=" md:my-4 md:h-72 h-40 "
                    src={selectedImage}
                    alt={`Product in ${selectedColor}`}
                  />
                  <div className=" flex flex-col absolute md:top-[25px] top-[2px] md:left-[10px] left-[2px] ">
                    <div className="">
                      <p className="blacktext flex justify-center border-2 border-black py-0.5 md:px-2 px-1 bg-white !mb-2 md:text-[12px] text-[10px] font-semibold shadow-md shadow-black-900  ">
                        NEW
                      </p>
                    </div>
                    <div className=" ">
                      <p className="redtext border-2 border-red-600 py-0.5 md:px-2 px-1 bg-white text-red-500 md:text-[12px] text-[12px] font-semibold shadow-md shadow-red-400  ">
                        SALE
                      </p>
                    </div>
                  </div>
                  <div className=" flex absolute md:top-[25px] top-[8px] md:right-[10px] right-[6px] cursor-pointer ">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                  </div>
                </div>

              </div>
              <div className=" flex flex-col items-center ">
                <p className=" font-semibold !mb-2 ">COCO LEE</p>
                <p className="  text-[14px] !mb-2 text-center ">
                  coco lee, coins are kumis brown leather bag
                </p>
                <p className="font-semibold ">$199.00</p>
              </div>
            </div>
            
          </Slider>
        </div>
      </div>  */}

      <Footer />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ProductDetailPage;
