import React  from 'react';

export default function PaymentConfirmationCard({ orderItems }) {
    const rbgColor = 'rgb(76,24,76)';

    return (
        <div className="p-3 text-sm bg-light border-1 my-2">
            <div>
                <form action="https://ecom.urbankarmagb.co.uk/users/create-checkout-session" method="POST">
                    <button className="btn btn-dark rounded-pill w-100" style={{ backgroundColor: rbgColor }}>Pay Now</button>
                </form>
            </div>
            <div className="text-xs text-center my-2">
                You'll be securely redirected to Razorpay and complete your purchase.
            </div>
            <div className="fw-bold my-3">
                Order Summary
            </div>
            <div className="text-muted">
                Item: £
                {
                    orderItems.reduce((acc, orderItem) => {
                        return acc + (parseFloat(orderItem?.cartVariant?.productItem?.salePrice) * parseInt(orderItem?.quantity));
                    }, 0).toFixed(2)
                }

                <br />
                Delivery: <span className="text-success">Free</span>
                <br />
                <span className="fw-bold">
                    Discount: -£
                    {
                        orderItems.reduce((acc, orderItem) => {
                            const salePrice = parseFloat(orderItem?.cartVariant?.productItem?.salePrice);
                            const discountedPrice = parseFloat(orderItem?.cartVariant?.productItem?.discountedPrice);
                            const quantity = parseInt(orderItem?.quantity);

                            return acc + ((salePrice - discountedPrice) * quantity);
                        }, 0).toFixed(2)
                    }
                </span>
            </div>
            <div>
                <hr className="my-2" />
                <span className="fw-bold text-lg">
                    Total: £
                    {
                        orderItems.reduce((acc, orderItem) => {
                            const discountedPrice = parseFloat(orderItem?.cartVariant?.productItem?.discountedPrice);
                            const quantity = parseInt(orderItem?.quantity);

                            return acc + (discountedPrice * quantity);
                        }, 0).toFixed(2)
                    }
                </span>
                <hr className="my-2" />
            </div>
            <div className="text-muted text-xs">
                By placing your order, you agree to our <span className="text-dark">Terms of Use</span> and <span className="text-dark">Privacy Policy</span>.
            </div>
        </div>
    );
}