// reducer imports
import { removerCartProductVariant } from "../Cart/cartReducer";
import { updateCartProductVariantQuantity } from "../Cart/cartReducer";

// client imports
import { updateCartItemQuantity } from "../../Services/client";

import { useDispatch } from "react-redux";
import { useState } from "react";
import { removeCartItem } from "../../Services/client";

import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";

import "./ItemCard.css";
import React from "react";
import axios from "axios";

export default function ItemCard({
  itemId,
  productId,
  slug,
  title,
  size,
  quantity,
  salePrice,
  discountedPrice,
  maxQuantity,
  coverImage,
}) {
  const dispatch = useDispatch();

  const [currentQuantity, setCurrentQuantity] = useState(quantity);
  const [isLoading, setIsLoading] = useState(false);
  // const productIdData = { productId: productId };
  const productIdData = { productId: slug };
  // const productIdData = JSON.stringify({ productId: productId });
  // const handleMoveToWishlist = async () => {
  //   const axios = require("axios");
  //   let data = JSON.stringify({
  //     productId: 5,
  //   });

  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: "https://ecom.urbankarmagb.co.uk/users/wishlist/add",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization:
  //         "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImJjYjdkN2Y3LTVkMzMtNDc1ZC05M2E2LTVlMzEwODVlOTZkOSIsImVtYWlsIjoiYW1pdEBnbWFpbC5jb20iLCJuYW1lIjoiQW1pdCIsInByb2ZpbGVJbWFnZSI6Imh0dHBzOi8vaS5pbWd1ci5jb20vVTFkeGdkYS5wbmciLCJwaG9uZSI6bnVsbCwiaWF0IjoxNzA5OTQxOTY2LCJleHAiOjE3NDE0OTk1NjYsImlzcyI6IkJvZHkgRGVzaWduZXIifQ.xB14LfWllVDiaqoA4fdr4H6AazJv5Lf20ROBEBL8LNU",
  //     },
  //     data: data,
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const handleMoveToWishlist = () => {
    axios
      .post(
        `https://ecom.urbankarmagb.co.uk/users/wishlist/add`,
        {
          headers: {
            // ContentType: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // productIdData,
        },
        productIdData
      )
      .then((k) => {
        console.log(k);
        handleRemoveItem();
      })
      .catch((l) => {
        console.log(l);
      });
  };
  const handleRemoveItem = async () => {
    try {
      await removeCartItem(itemId);
      dispatch(removerCartProductVariant(itemId));
    } catch (error) {
      console.error("Error removing cart product variant:", error);
    }
  };

  const handleQuantityChange = (change) => {
    setCurrentQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      return newQuantity >= 1 && newQuantity <= maxQuantity
        ? newQuantity
        : prevQuantity;
    });
  };

  const handleSubmitQuantityChange = () => {
    setIsLoading(true);

    updateCartItemQuantity(itemId, currentQuantity)
      .then(() => {
        dispatch(
          updateCartProductVariantQuantity({
            id: itemId,
            quantity: currentQuantity,
          })
        );
      })
      .catch((error) => {
        console.error("Error updating cart item quantity:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="bg-light border-1 p-2 rounded margin-container my-2">
      <div className="row">
        <div className="col-2">
          <img
            src={coverImage}
            alt="dress"
            className="m-1"
            style={{ width: "100px", height: "140px", objectFit: "cover" }}
          />
        </div>
        <div className="col-10 my-1 d-flex flex-column justify-content-between">
          <div>
            <div className="row">
              <div className="d-flex ps-0 justify-content-between">
                <div className="font-weight-bold">{title}</div>
                <div className="d-flex align-items-center gap-2">
                  <span className="text-decoration-line-through text-secondary">
                    £{(salePrice * currentQuantity).toFixed(2)}
                  </span>
                  <span className="text-danger">
                    £{(discountedPrice * currentQuantity).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">Size: {size}</div>
            <div className="row">
              <div className="d-flex align-items-center ps-0">
                Quantity:
                <button
                  disabled={currentQuantity === 1}
                  onClick={() => handleQuantityChange(-1)}
                  className="btn btn-sm custom-icon-btn"
                >
                  <BiMinusCircle />
                </button>
                <span>{currentQuantity}</span>
                <button
                  onClick={() => handleQuantityChange(1)}
                  disabled={currentQuantity === maxQuantity}
                  className="btn btn-sm custom-icon-btn"
                >
                  <BiPlusCircle />
                </button>
              </div>
            </div>
          </div>

          <hr style={{ marginLeft: "-.75rem" }} />

          <div className="d-flex justify-content-between">
            <div>
              <button
                className="btn btn-dark btn-sm me-2 rounded-pill"
                style={{ marginLeft: "-0.75rem" }}
                onClick={handleMoveToWishlist}
              >
                MOVE TO WISHLIST
              </button>
            </div>
            {currentQuantity != quantity && (
              <div>
                <button
                  style={{ background: "rgb(50,190,166)", color: "white" }}
                  className="btn btn-sm rounded-pill"
                  onClick={handleSubmitQuantityChange}
                >
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  ) : (
                    "Update Cart"
                  )}
                </button>
              </div>
            )}
            <div>
              <button
                className="justify-content-end btn btn-dark btn-sm rounded-pill"
                onClick={handleRemoveItem}
              >
                REMOVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
