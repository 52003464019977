import React  from 'react';

export default function FinalOrderItemCard({ orderItem, accentColor }) {
    console.log("Order itemmm: ", orderItem);
    return (
        <div className='bg-light border-1 p-2 rounded margin-container my-2'>
            <div className='row'>
                <div className='col-2'>
                    <img src={orderItem.cartVariant?.productItem?.coverImage} alt='dress' className='m-1' style={{ width: '100px', height: '140px', objectFit: 'cover' }} />
                </div>
                <div className='col-10 my-1 d-flex flex-column justify-content-between'>
                    <div>
                        <div className='row'>
                            <div className='d-flex ps-0 justify-content-between'>
                                <div 
                                    className='font-weight-bold'
                                    style={{ color: accentColor }}
                                >
                                    {orderItem.cartVariant?.productItem?.name}
                                </div>

                            </div>
                        </div>
                        <div className='row text-sm'>
                            Size: {orderItem.cartVariant.size}
                        </div>
                        <div className='row text-sm'>
                            <div className="d-flex align-items-center ps-0">
                                Quantity:
                                <span>{orderItem.quantity}</span>
                            </div>
                        </div>
                        <div className="row fw-bold">
                        £ {orderItem.cartVariant.productItem.discountedPrice * orderItem.quantity}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
