import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { IconButton } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import "../WomenPage/WomenPage.css";
const CategorySlug = (props) => {
  //
  const { categoryName } = useParams();

  const [products, setProducts] = useState([]);

  const apiCall = () => {
    // Category by slug
    axios
      .get(
        `https://ecom.urbankarmagb.co.uk/product/category/${categoryName}?length=10&start=1`
      )
      .then((k) => {
        console.log("Start");
        let a = k.data;
        let got_products = a["products"];
        console.log(got_products);
        setProducts(got_products);
      })
      .catch((l) => {
        console.log("Error Found");
      });
  };

  useEffect(() => {
    // Slug
    apiCall();
  }, []);

  return (
    <div>
      <Header />

      <div className="accessories-container-main women-main-container">
        <div className="accessories-container">
          {products.map((singleItem, index) => {
            return (
              <>
                <>
                  <div className="accessories-item" key={singleItem[""]}>
                    <Link to={`/product/${singleItem["slug"]}`}>
                      <img src={singleItem["coverImage"]} />
                    </Link>
                    <p className="accessories-category-1">
                      {singleItem["name"]}
                    </p>
                    {/* <p>{singleItem["description"]}</p> */}
                    <a
                      href="#"
                      style={{
                        textDecoration: "line-through",
                        marginRight: "5px",
                        color: "#8d8d8d",
                      }}
                    >
                      £{singleItem["salePrice"]}
                    </a>
                    <span className="newPrice">
                    £{singleItem["discountedPrice"]}
                    </span>

                    <button className="new-button new-sale-nutton">NEW</button>
                    <button className="sale-button new-sale-nutton">
                      SALE
                    </button>
                    <IconButton className="favourite-icon favourite-icon-women">
                      <FavoriteIcon />
                    </IconButton>

                    <Link to={`/product/${singleItem["slug"]}`}>
                      <button id={singleItem["slug"]}>View</button>
                    </Link>

                    {/* // View button -> product page ()
                // click slug 
                // slug product page (parameter)
                // use parameter to access. */}
                    {/* <button className="add-to-cart-hover" onClick={() => {}}>
                      + ADD TO CART
                    </button> */}
                  </div>
                </>
              </>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CategorySlug;
