import { createSlice } from "@reduxjs/toolkit";

const initialAddressState = {
    success: false,
    addresses: [],  
    address: {},
}

const addressSlice = createSlice({
    name: "address",
    initialState: initialAddressState,
    reducers: {
        setAddress(state, action) {
            state.address = action.payload;
        },
        setAddresses(state, action) {
            state.addresses = action.payload;
        },
        addAddress(state, action) {
            state.addresses.push(action.payload);
        },
        updateAddress(state, action) {
            const { id, address } = action.payload;
            const addressIndex = state.addresses.findIndex((item) => item.id === id);
            state.addresses[addressIndex] = address;
        },
        removeAddress(state, action) {
            const { id } = action.payload;
            state.addresses = state.addresses.filter((item) => item.id !== id);
        },
        resetAddress(state) {
            state.address = {};
        }
    },
});

export const { setAddress, setAddresses, addAddress, updateAddress, removeAddress, resetAddress } = addressSlice.actions;
export default addressSlice.reducer;