import useStyles from "./styles";
import React, { useState, useEffect } from "react";
import CartContext, { useCart } from "../../Context/notes/CartContext";
import { useSelector, useDispatch } from "react-redux";
import { setCartProductVariants } from "./cartReducer";
import "./Cart.css";
import {
  Card,
  CardActions,
  CardMedia,
  IconButton,
  CardContent,
  Typography,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillFire } from "react-icons/ai";
import { getCartItems } from "../../Services/client";
import ProgressSteps from "../ProgressSteps";

// import { IconButton } from "@material-ui/core";

// const Cart = ({ cartProductVariants, productdetail }) => {
export default function Cart({ productdetail }) {
  // refactoring cartProductVariants
  const cartProductVariants = useSelector(
    (state) => state.cartReducer.cartProductVariants
  );

  const dispatch = useDispatch();

  // set the auth token every time
  useEffect(() => {
    getCartItems().then((res) => {
      dispatch(setCartProductVariants(res.cartProductVariants));
    });
  }, []);

  // Sample cart array of objects
  const cart = [
    { id: 1, name: "Product 1", price: 10.0, quantity: 2 },
    { id: 2, name: "Product 2", price: 15.0, quantity: 1 },
    // ... more products
  ];

  // You can add new products to the cart as objects
  const newProduct = { id: 3, name: "Product 3", price: 20.0, quantity: 3 };
  cart.push(newProduct);

  // You can access and modify cart items by index
  const productToUpdate = cart.find((item) => item.id === 2);
  if (productToUpdate) {
    productToUpdate.quantity += 1; // Update quantity of an existing product
  }

  const classes = useStyles();

  const cartProductVariantsLength = cartProductVariants?.length;

  const [selectedSize, setSelectedSize] = useState("medium"); // Initial size selection
  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };
  const product1 = {
    name: "Product Name",
    price: 19.99,
    description: "Product Description",
    // Add other product details here
  };
  const availableSizes = ["S", "M", "L"]; // Define available size options

  const [selectedColor, setSelectedColor] = useState("blue"); // Initial color selection
  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  // Define an object that maps color options to image URLs
  const colorOptions = {
    blue: "./bluedress.jpeg",
    red: "./reddress.jpeg",
    // green: 'green-product-image.jpg',
  };
  // Get the image URL based on the selected color
  const selectedImage = colorOptions[selectedColor];

  const [quantity, setQuantity] = useState(1);
  const handleQuantityChange = (change) => {
    const newQuantity = quantity + change;
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
    }
  };

  // const { cart } = useCart();
  const a = useContext(CartContext);

  return (
    <>
      {/* <div className="cart-items">
        {cartProductVariantsLength === 0 && (
          <div className={classes.emptyCart}>Your cart is empty</div>
        )}
      </div> */}
      <div>
        {cartProductVariants?.length >= 0 && (
          <div className="container-fluid">
            <div className="row">
              <div
                style={{ width: "70vw" }}
                className="col-md-6 mx-auto d-flex justify-content-center align-items-center"
              >
                <ProgressSteps />
              </div>
            </div>
          </div>
        )}
        <hr />
        {cartProductVariants?.length > 0 && (
          <div className="payContainer">
            <div className="grandTotal">
              {/* <h4>
                GRAND TOTAL : $
                {cartProductVariants.reduce((acc, item) => {
                  return (
                    acc + parseInt(item.newPrice) * parseInt(item.quantity)
                  );
                }, 0)}
              </h4> */}
            </div>
            {/* 
            <Link className="proceedLink" to="/checkout">
              <button className="proceedToCheckout">PROCEED TO CHECKOUT</button>
            </Link> */}
          </div>
        )}
      </div>
      <div className="">
        <p className="">
          {a.state?.orderid && `your product no. is : ${a.state.orderid}`}
        </p>
      </div>

      {/* <div className="font-bold text-black"> */}
      {/* {cart.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <ul>
          
        </ul>
      )} */}
      {/* Product ID: {a.orderid} */}
      {/* </div> */}
    </>
  );
}
